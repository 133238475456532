import React, { useState, useEffect, useCallback } from "react";
import { Box, Button, Card, CircularProgress, Divider, Grid, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { CardMedia } from '@mui/material';
import { blue, green, grey } from "@mui/material/colors";
import axios from 'axios';
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import { formatToken, formatTokenDecimals } from "../../helpers/GeneralFunction";
import { BrowserView, MobileView } from 'react-device-detect';
import { FileCopyButton } from "../../component/copyButton/fileCopyButton";
const commonBodySchema = {
    secretKey: process.env.REACT_APP_SECRET,
    rpp: 10,
    page_no: 1
};

let counter = 0;

const Home = () => {
    const [search, setSearch] = useState("");
    const [blockList, setBlockList] = useState([]);
    const [tokenList, setTokenList] = useState([]);
    const [transactionList, setTransactionList] = useState([]);
    const [coinInfo, setCoinInfo] = useState([]);
    const [isLoadedBlocks, setIsloadedblocks] = useState(true);
    const [isLoadedToken, setIsloadedToken] = useState(true);
    const [isLoadedTransaction, setIsloadedTransaction] = useState(true);
    const [selectfilters, setSelectFilters] = useState("all");
    const navigate = useNavigate();

    const getBlockTransaction = () => {
        return new Promise((resolve, reject) => {
            axios.post(process.env.REACT_APP_BASE_URL + '/api/block/list/v01', {
                secretKey: process.env.REACT_APP_SECRET,
                rpp: 10,
                page_no: 1
            })
                .then((response) => {
                    let res = response.data;
                    console.log('setBlockList', res);
                    setBlockList(res.result.blockList);
                    resolve(res);
                }).catch((err) => {
                    reject(err);
                    console.log(err);
                }).finally(() => {
                    setIsloadedblocks(false);
                });
        });
    }

    const getTokenList = () => {
        return new Promise((resolve, reject) => {
            axios.post(process.env.REACT_APP_BASE_URL + '/api/asset/list/v01', {
                secretKey: process.env.REACT_APP_SECRET,
                rpp: 10,
                page_no: 1
            })
                .then((response) => {
                    let res = response.data;
                    console.log(res);
                    setTokenList(res.result.assetList);
                    resolve(res);
                }).catch((err) => {
                    reject(err);
                    console.log(err);
                }).finally(() => {
                    setIsloadedToken(false);
                });
        });
    }
    const formatNumberWithSeparator = (number) => {
        // Mengonversi angka menjadi string untuk mempermudah manipulasi
        const numberString = number.toString();

        // Memisahkan bagian desimal (jika ada)
        const [integerPart, decimalPart] = numberString.split('.');

        // Menambahkan separator ribuan pada bagian bilangan bulat
        const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        // Menggabungkan kembali bagian bilangan bulat dan desimal (jika ada)
        const formattedNumber = decimalPart
            ? `${formattedIntegerPart}.${decimalPart}`
            : formattedIntegerPart;

        return formattedNumber;
    }


    const getTransactions = () => {
        return new Promise((resolve, reject) => {
            axios.post(process.env.REACT_APP_BASE_URL + '/api/transaction/list/v01', {
                secretKey: process.env.REACT_APP_SECRET,
                rpp: 10,
                page_no: 1,
                asset: "",
                account: ""
            })
                .then((response) => {
                    let res = response.data;
                    setTransactionList(res.result.transactionList);
                    resolve(res);
                }).catch((err) => {
                    reject(err);
                    console.log(err);
                }).finally(() => {
                    setIsloadedTransaction(false);
                });
        });
    }

    const getCoinInfo = () => {
        return new Promise((resolve, reject) => {
            axios.post(process.env.REACT_APP_BASE_URL + '/api/coin/info/v01', {
                secretKey: process.env.REACT_APP_SECRET
            })
                .then((response) => {
                    let res = response.data;
                    console.log('res_/coin/info', res)
                    setCoinInfo(res.result);
                    resolve(res);
                }).catch((err) => {
                    reject(err);
                    console.log(err);
                }).finally(() => {
                    setIsloadedTransaction(false);
                });
        });
    }

    const handleChange = (e) => {
        setSelectFilters(e.target.value);
    }
    console.log('SELECTFILTERS', selectfilters)
    const allSearch = async () => {
        return new Promise((resolve, reject) => {
            axios.post(process.env.REACT_APP_BASE_URL + '/api/globalsearch/v01', {
                secretKey: process.env.REACT_APP_SECRET,
                keyword: search
            })
                .then((response) => {
                    let res = response.data;
                    console.log("Search Global Result: ", res.result);
                    if (res.status) {

                        if (res.result.account === undefined) {
                            if (res.result.transaction !== undefined) {
                                navigate("/transaction-detail/" + res.result.transaction.transaction);
                            }

                            if (res.result.block !== undefined) {
                                navigate("/block-detail/" + res.result.block.block);
                            }
                        } else {
                            if (res.result.account.account === "") {
                                navigate(`/not-found/${search}`);
                            } else {
                                navigate("/account-detail/" + res.result.account.account);
                            }
                        }


                        // if(res.result.account !== undefined){
                        //     navigate("/account-detail/"+res.result.account.account);
                        // }
                    } else {
                        alert(res.message);
                    }


                    // if(res.result)
                    resolve(res);
                }).catch((err) => {
                    reject(err);
                    console.log(err);
                });
        });
    }
    const getBlockDetail = () => {
        return new Promise((resolve, reject) => {
            axios.post(process.env.REACT_APP_BASE_URL + `/api/block/detail/v01`, {
                secretKey: process.env.REACT_APP_SECRET,
                block: search,
                height: 1
            })
                .then((response) => {
                    let res = response.data;
                    console.log('block/detail', res)
                    if (Object.keys(res.result).length === 0) {
                        navigate(`/not-found/${search}`);
                    } else {
                        navigate("/block-detail/" + res.result.block.block);
                    }
                    resolve(res);
                }).catch((err) => {
                    reject(err);
                    console.log(err);
                })
        });
    }
    const getAccountInfo = () => {
        return new Promise((resolve, reject) => {
            axios.post(process.env.REACT_APP_BASE_URL + `/api/account/info/v01`, {
                secretKey: process.env.REACT_APP_SECRET,
                account: search
            })
                .then((response) => {
                    let res = response.data;
                    console.log('RESONTO', res)
                    if (res.result.account.account === "") {
                        navigate(`/not-found/${search}`);
                    } else {
                        navigate("/account-detail/" + res.result.account.account);
                    }
                    resolve(res);
                }).catch((err) => {
                    reject(err);
                    console.log(err);
                })
        });
    }
    const getTransactionsDetail = () => {
        return new Promise((resolve, reject) => {
            axios.post(process.env.REACT_APP_BASE_URL + `/api/transaction/detail/v01`, {
                secretKey: process.env.REACT_APP_SECRET,
                transaction: search
            })
                .then((response) => {
                    let res = response.data;
                    console.log('transaction/detail', res)
                    if (Object.keys(res.result.transaction).length === 0) {
                        navigate(`/not-found/${res.result.transaction.transaction}`);
                    } else {
                        navigate("/transaction-detail/" + res.result.transaction.transaction);
                    }
                    resolve(res);
                }).catch((err) => {
                    reject(err);
                    console.log(err);
                })
        });
    }
    const onSearch = () => {
        console.log('selectfilters', selectfilters)
        let type = selectfilters;
        console.log('type_onSearch', type)
        switch (type) {
            case "all":
                allSearch();
                break;
            case "account":
                getAccountInfo();
                break;
            case "block":
                getBlockDetail();
                break;
            case "transaction":
                getTransactionsDetail();
                break;
            default:
                break;
        }
    }

    const formatRupiah = (angka = 0, prefix) => {
        var number_string = angka.toString().replace(/[^,\d]/g, ''),
            split = number_string.split(','),
            sisa = split[0].length % 3,
            rupiah = split[0].substr(0, sisa),
            ribuan = split[0].substr(sisa).match(/\d{3}/gi);

        if (ribuan) {
            var separator = sisa ? '.' : '';
            rupiah += separator + ribuan.join('.');
        }
        rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah;
        return prefix === undefined ? rupiah : prefix + ' ' + rupiah;
    };

    const converter = (num, decimal) => {
        // let text = formatRupiah(num);
        return parseInt(num).toFixed(decimal).replace(".", ",");
    }

    const initialization = async () => {
        await getCoinInfo();
        await getBlockTransaction();
        await getTransactions();
        await getTokenList();

    }

    useEffect(() => {
        initialization().catch((err) => console.log(err));

    }, []);
    console.log('HI RENDER', (counter += 1))
    return (
        <Box>
            <Grid container>
                <Grid item md={12} xs={12}>
                    <CardMedia image="/images/background_home.png" style={{ height: '65vh', width: '100%', marginTop: -30 }}>
                        {/* <Stack direction={"column"} spacing={2} alignItems={"flext-start"} sx={{ pt: 30, pl: 5, pr: 5 }}>
                            <Typography variant="body1" style={{ fontSize: 25, color: '#DBBA52', fontWeight: '800' }}>DWA Exploler</Typography> */}
                        <MobileView>
                            <Stack direction={"column"} spacing={2} alignItems={"flext-start"} sx={{ pt: 5, pl: 5, pr: 5 }}>
                                <Typography variant="body1" style={{ fontSize: 25, color: '#DBBA52', fontWeight: '800' }}>DWA Explorer</Typography>
                                <Stack direction={"row"} style={{ backgroundColor: 'white', width: '100%', borderRadius: 5 }}>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={selectfilters}
                                        onChange={handleChange}
                                        label="All Filters"
                                        sx={{ width: '30%', fontSize: 12, height: '100%' }}
                                    >
                                        <MenuItem value={"all"}>All</MenuItem>
                                        <MenuItem value={"account"}>Account</MenuItem>
                                        <MenuItem value={"block"}>Block</MenuItem>
                                        <MenuItem value={"transaction"}>Transaction</MenuItem>
                                    </Select>
                                    <TextField
                                        onKeyDown={(ev) => {
                                            // console.log(`Pressed keyCode ${ev.key}`);
                                            if (ev.key === 'Enter') {
                                                onSearch();
                                                ev.preventDefault();
                                            }
                                        }}
                                        placeholder="Search by Block / Transaction Id / Address" value={search} onChange={(e) => setSearch(e.target.value)} InputLabelProps={{ shrink: true }} sx={{ minWidth: '70%', input: { color: 'black', fontSize: 12 }, fieldset: {} }} />

                                </Stack>
                            </Stack>
                        </MobileView>
                        <BrowserView>
                            <Stack direction={"column"} spacing={2} alignItems={"flext-start"} sx={{ pt: 30, pl: 5, pr: 5 }}>
                                <Typography variant="body1" style={{ fontSize: 25, color: '#DBBA52', fontWeight: '800' }}>DWA Exploler</Typography>
                                <Stack direction={"row"} style={{ backgroundColor: 'white', width: '100%', borderRadius: 5 }}>

                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={selectfilters}
                                        onChange={handleChange}
                                        label="All Filters"
                                        shrink={true}
                                        sx={{ width: 200 }}
                                    >
                                        <MenuItem value={"all"}>All</MenuItem>
                                        <MenuItem value={"account"}>Account</MenuItem>
                                        <MenuItem value={"block"}>Block</MenuItem>
                                        <MenuItem value={"transaction"}>Transaction</MenuItem>
                                    </Select>
                                    <TextField
                                        onKeyDown={(ev) => {
                                            // console.log(`Pressed keyCode ${ev.key}`);
                                            if (ev.key === 'Enter') {
                                                onSearch();
                                                ev.preventDefault();
                                            }
                                        }}
                                        placeholder="Search by Block / Transaction Id / Address" value={search} onChange={(e) => setSearch(e.target.value)} InputLabelProps={{ shrink: true }} sx={{ width: '100%', input: { color: 'black' }, fieldset: { borderColor: "white" } }} />

                                </Stack>
                            </Stack>
                        </BrowserView>


                        <BrowserView>
                            <Grid container
                                spacing={0}
                                direction={'column'}
                                alignItems="center"
                                justify="center"
                                style={{ minHeight: '100vh' }} sx={{ pt: 4 }}>
                                <Grid item md={12} sx={{ backgroundColor: grey[50], p: 3, width: "90%", borderRadius: 10 }}>
                                    <Grid container sx={{ mt: 1 }}>

                                        <Grid item md={6}>
                                            <Stack direction={"column"} spacing={3} sx={{ marginLeft: 3 }}>
                                                <Stack direction={"row"} alignItems="center">
                                                    <Stack direction={"row"}>
                                                        <img src={"/images/icon1.png"} style={{ width: 35, height: 35 }} />
                                                    </Stack>
                                                    <Stack direction={"column"} sx={{ ml: 3 }}>
                                                        <Typography style={{ fontWeight: 'bold' }}>Total Supplys</Typography>
                                                        <Typography>{coinInfo.DWACoin ? formatTokenDecimals(coinInfo.DWACoin?.total_supply, coinInfo.DWACoin?.decimal) : null}</Typography>
                                                    </Stack>
                                                </Stack>
                                                <Stack direction={"row"} alignItems="center">
                                                    <Stack direction={"row"}>
                                                        <img src={"/images/icon3.png"} style={{ width: 35, height: 35 }} />
                                                    </Stack>
                                                    <Stack direction={"column"} sx={{ ml: 3 }}>
                                                        <Typography style={{ fontWeight: 'bold' }}>Circulation</Typography>
                                                        <Typography>{formatRupiah(coinInfo.DWACoin?.total_circulation)}</Typography>
                                                    </Stack>
                                                </Stack>
                                            </Stack>
                                        </Grid>
                                        <Grid item md={6}>
                                            <Stack direction={"column"} spacing={3}>
                                                <Stack direction={"row"} alignItems="center">
                                                    <Stack direction={"row"}>
                                                        <img src={"/images/icon2.png"} style={{ width: 35, height: 35 }} />
                                                    </Stack>
                                                    <Stack direction={"column"} sx={{ ml: 3 }}>
                                                        <Typography style={{ fontWeight: 'bold' }}>Latest Block</Typography>
                                                        <Typography>{coinInfo.DWACoin ? formatNumberWithSeparator(coinInfo.DWACoin?.numberOfBlocks) : null}</Typography>
                                                    </Stack>
                                                </Stack>
                                                <Stack direction={"row"} alignItems="center">
                                                    <Stack direction={"row"}>
                                                        <img src={"/images/icon4.png"} style={{ width: 35, height: 35 }} />
                                                    </Stack>
                                                    <Stack direction={"column"} sx={{ ml: 3 }}>
                                                        <Typography style={{ fontWeight: 'bold' }}>Last Safe Block</Typography>
                                                        <Typography>{coinInfo.DWACoin ? formatNumberWithSeparator(coinInfo.DWACoin?.currentMinRollbackHeight) : null}</Typography>
                                                    </Stack>
                                                </Stack>
                                            </Stack>
                                        </Grid>


                                    </Grid>
                                </Grid>
                            </Grid>
                        </BrowserView>
                        <MobileView>
                            <Grid container
                                spacing={0}
                                direction={'column'}
                                alignItems="center"
                                justify="center"
                                style={{ minHeight: '100vh' }} sx={{ pt: 4 }}>
                                <Grid item md={12} sx={{ backgroundColor: grey[50], p: 3, width: "90%", borderRadius: 10 }}>
                                    <Grid container sx={{ mt: 1 }}>
                                        <Grid item md={6}>
                                            <Stack direction={"column"} spacing={3} sx={{ marginLeft: 3 }}>
                                                <Stack direction={"row"} alignItems="center">
                                                    <Stack direction={"row"}>
                                                        <img src={"/images/icon1.png"} style={{ width: 25, height: 25 }} />
                                                    </Stack>
                                                    <Stack direction={"column"} sx={{ ml: 3 }}>
                                                        <Typography style={{ fontWeight: 'bold', fontSize: 14 }}>Total Supply</Typography>
                                                        <Typography>{formatTokenDecimals(coinInfo.DWACoin?.total_supply, coinInfo.DWACoin?.decimal)}</Typography>
                                                    </Stack>
                                                </Stack>
                                                <Stack direction={"row"} alignItems="center">
                                                    <Stack direction={"row"}>
                                                        <img src={"/images/icon3.png"} style={{ width: 25, height: 25 }} />
                                                    </Stack>
                                                    <Stack direction={"column"} sx={{ ml: 3 }}>
                                                        <Typography style={{ fontWeight: 'bold', fontSize: 14 }}>Circulation</Typography>
                                                        <Typography>{formatRupiah(coinInfo.DWACoin?.total_circulation)}</Typography>
                                                    </Stack>
                                                </Stack>
                                                <Stack direction={"row"} alignItems="center">
                                                    <Stack direction={"row"}>
                                                        <img src={"/images/icon2.png"} style={{ width: 25, height: 25 }} />
                                                    </Stack>
                                                    <Stack direction={"column"} sx={{ ml: 3 }}>
                                                        <Typography style={{ fontWeight: 'bold', fontSize: 14 }}>Latest Block</Typography>
                                                        <Typography>{coinInfo.DWACoin?.numberOfBlocks}</Typography>
                                                    </Stack>
                                                </Stack>
                                                <Stack direction={"row"} alignItems="center">
                                                    <Stack direction={"row"}>
                                                        <img src={"/images/icon4.png"} style={{ width: 25, height: 25 }} />
                                                    </Stack>
                                                    <Stack direction={"column"} sx={{ ml: 3 }}>
                                                        <Typography style={{ fontWeight: 'bold', fontSize: 14 }}>Last Safe Block</Typography>
                                                        <Typography>{coinInfo.DWACoin?.currentMinRollbackHeight}</Typography>
                                                    </Stack>
                                                </Stack>
                                            </Stack>
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </MobileView>
                    </CardMedia>
                </Grid>

            </Grid>

            <Grid container bgcolor={'white'} spacing={1} sx={{ pl: 2, pr: 2, pt: 15, pb:5 }}>
                <Grid item md={6} xs={12}>
                    <Stack direction={"column"} spacing={2}>
                        <Box sx={{ border: 0.5, borderColor: 'gray', p: 1, borderRadius: 2 }}>
                            <Grid container>
                                <Grid item md={12}>Blocks</Grid>
                            </Grid>
                            <Divider sx={{ mt: 1, mb: 1 }} />
                            <Box sx={{ padding: 0, overflowX: 'auto' }}>
                                {!isLoadedBlocks && blockList.length > 0 ?
                                    blockList.map((x, i) =>
                                        <>
                                            <Grid container key={i} >
                                                <Grid item md={20} xs={20} sx={{ mt: 1, mb: 1 }} >
                                                    <Stack direction={"row"} spacing={{ xs: 1, md: 4 }} style={{height:60}}>
                                                        <Stack direction={"row"} alignItems={"center"} sx={{ minWidth: 40, minHeight:40 }}>
                                                            <label style={{ padding: 10, backgroundColor: grey[200], borderRadius: 50 }}>Bk</label>
                                                        </Stack>
                                                        <Stack direction={"row"} justifyContent={"flex-start"} sx={{ minWidth: 200 }}>
                                                            <Stack direction={"column"}>
                                                                <Stack direction={"row"} alignItems={"center"}>
                                                                    <a href="#" style={{ textDecoration: "none" }}><Typography style={{ fontWeight: "500", fontSize: 14, color: blue[500] }} onClick={() => navigate("/block-detail/" + x.block)}>{x.block}</Typography></a>
                                                                    {/* <Button alignItems={"start"} style={{ minWidth: 10 }} variant="text" onClick={() => { navigator.clipboard.writeText(x.block) }}>
                                                                        <FileCopyIcon style={{ color: "#DBBA52", fontSize: 16 }} />
                                                                    </Button> */}
                                                                </Stack>
                                                              
                                                                <Typography color={grey[500]} style={{ fontSize: 12 }}>{moment(new Date(x.timestamp)).startOf("minute").fromNow()} </Typography>
                                                            </Stack>
                                                            <FileCopyButton param={x.block}/>
                                                        </Stack>
                                                        <Stack direction={"column"}>
                                                            <Typography variant="body1" style={{ fontWeight: "600", fontSize: 14 }} sx={{ minWidth: 200 }}>Total Transaction</Typography>
                                                            <Typography color={grey[500]} style={{ fontSize: 12 }}>{x.transactions.length}</Typography>
                                                        </Stack>
                                                        <Stack direction={"column"}>
                                                            <Typography variant="body1" style={{ fontWeight: "600", fontSize: 14 }}>Height</Typography>
                                                            <Typography color={grey[500]} style={{ fontSize: 12 }}>{formatToken(x.height)}</Typography>
                                                        </Stack>
                                                    </Stack>

                                                    <Divider sx={{ mt: 1, mb: 1 }} />
                                                </Grid>
                                            </Grid>
                                        </>
                                    ) : <Box display="flex" justifyContent="center"
                                        alignItems="center"
                                        minHeight="50vh">
                                        <CircularProgress color="inherit" /> &nbsp;
                                        <Typography style={{ fontSize: 12, color: grey[600] }}>Getting data...</Typography>
                                    </Box>}
                            </Box>
                            <Stack>
                                <Button variant="contained" fullWidth sx={{ background: '#F8F1DC' }} style={{ color: 'black' }} onClick={() => navigate("/blocks")}>See More</Button>
                            </Stack>
                        </Box>

                        <Box sx={{ border: 0.5, borderColor: 'gray', p: 1, borderRadius: 2 }}>
                            <Grid container>
                                <Grid item md={12}>Token List</Grid>
                            </Grid>
                            <Divider sx={{ mt: 1, mb: 1 }} />
                            <Box style={{ overflowX: 'auto' }}>

                                {!isLoadedToken && tokenList.length > 0 ?
                                    tokenList.map((x, i) =>
                                        <>
                                            <Grid container key={i}>
                                                <Grid item md={20} xs={20} sx={{ mt: 1, mb: 1 }}>
                                                    <Stack direction={"row"} spacing={{ xs: 1, md: 4 }}>
                                                        <Stack direction={"row"} alignItems={"center"} sx={{ minWidth: 200 }}>
                                                            <img src={x.asset_imagepath} style={{ height: 35, width: 35 }} />
                                                            <a href="#" style={{ textDecoration: "none" }}><Typography sx={{ ml: 1 }} style={{ fontWeight: "800", fontSize: 14 }} onClick={() => navigate("/token-detail/" + x.asset)}>{x.name}</Typography></a>
                                                        </Stack>
                                                        <Stack direction={"column"} sx={{ minWidth: 200 }}>
                                                            <Typography variant="body1" style={{ fontWeight: "600", fontSize: 14 }}>Total Supply</Typography>
                                                            <Typography color={grey[500]} style={{ fontSize: 12 }}>{x.initialQuantityQNT ? formatTokenDecimals(x.initialQuantityQNT, x.decimals) : 0}</Typography>
                                                        </Stack>
                                                        <Stack direction={"column"} sx={{ minWidth: 200 }}>
                                                            <Typography variant="body1" style={{ fontWeight: "600", fontSize: 14 }}>Creator</Typography>
                                                            <a href="#" style={{ textDecoration: "none" }}><Typography style={{ fontWeight: "500", fontSize: 12, color: blue[500] }} onClick={() => navigate("/account-detail/" + x.account)}>{x.account !== "" ? x.account : x.account}</Typography></a>
                                                            {/* <Typography color={grey[500]} style={{ fontSize: 12 }}>{x.account}</Typography> */}
                                                        </Stack>
                                                    </Stack>
                                                    <Divider sx={{ mt: 1, mb: 1 }} />
                                                </Grid>
                                            </Grid>
                                        </>
                                    ) : <Box display="flex" justifyContent="center"
                                        alignItems="center"
                                        minHeight="50vh">
                                        <CircularProgress color="inherit" /> &nbsp;
                                        <Typography style={{ fontSize: 12, color: grey[600] }}>Getting data...</Typography>
                                    </Box>}

                            </Box>
                            <Stack>
                                <Button variant="contained" fullWidth sx={{ background: '#F8F1DC' }} style={{ color: 'black' }} onClick={() => navigate("/token")}>See More</Button>
                            </Stack>
                        </Box>
                    </Stack>
                </Grid>

                <Grid item md={6} xs={12}>
                    <Stack direction={"column"} spacing={2}>
                        <Box sx={{ border: 0.5, borderColor: 'gray', p: 1, borderRadius: 2 }}>
                            <Grid container>
                                <Grid item md={12}>Transactions</Grid>
                            </Grid>
                            <Divider sx={{ mt: 1, mb: 1 }} />
                            <Box sx={{ padding: 0, overflowX: 'auto' }}>
                                {!isLoadedTransaction && transactionList.length > 1 ?
                                    transactionList.map((x, i) =>
                                        <>

                                            <Grid container key={i}>
                                                <Grid item md={20} xs={20} sx={{ mt: 1, mb: 1 }}>
                                                    <Stack direction={"row"} spacing={{ xs: 1, md: 4 }} style={{height:60}} >
                                                        <Stack direction={"row"} alignItems={"center"} sx={{ minWidth: 40, minHeight:40 }}>
                                                            <label style={{ padding: 10, backgroundColor: grey[200], borderRadius: 50 }}>Tx</label>
                                                        </Stack>
                                                        <Stack direction={"row"} alignItems={"center"} spacing={1} sx={{ minWidth: 200 }}>
                                                            <Stack direction={"column"}>
                                                                <a href="#" style={{ textDecoration: "none" }}><Typography style={{ fontWeight: "500", fontSize: 14 }} onClick={() => navigate("/transaction-detail/" + x.transaction)}>{x.transaction}</Typography></a>
                                                                <Typography color={grey[500]} style={{ fontSize: 12 }}>{moment(x.timestamp).format("YYYY-MM-DD hh:mm:ss")}  </Typography>

                                                            </Stack>
                                                            <FileCopyButton param={x.transaction}/>
                                                        </Stack>
                                                        <Stack direction={"column"} justifyContent={"center"} sx={{ minWidth: 200 }}>
                                                            <Stack direction={"row"} spacing={3} justifyContent={"space-between"}>
                                                                <span style={{ color: grey[500], fontSize: 12 }}>From </span>
                                                                <a href="#" style={{ textDecoration: "none" }}><Typography style={{ fontWeight: "500", fontSize: 12, color: blue[500] }} onClick={() => navigate("/account-detail/" + x.sender)}>{x.sender_tag !== "" ? x.sender_tag : x.sender}</Typography></a>
                                                            </Stack>
                                                            <Stack direction={"row"} spacing={3} justifyContent={"center"} >
                                                                <span style={{ color: grey[500], fontSize: 12 }}>To </span>
                                                                <a href="#" style={{ textDecoration: "none" }}><Typography style={{ fontWeight: "500", fontSize: 12, color: blue[500] }} onClick={() => navigate("/account-detail/" + x.recipient)}>{x.recipient_tag !== "" ? x.recipient_tag : x.recipient}</Typography></a>
                                                            </Stack>
                                                        </Stack>
                                                        <Stack direction={"column"} sx={{ minWidth: 50 }} justifyContent={"center"} >
                                                            <Typography variant="body1" sx={{ border: 0.5, borderColor: 'gray', borderRadius: 1, p: 0.3 }} style={{ fontSize: 14, fontWeight: '600' }}>DWA {x.amountNQT}</Typography>
                                                            <Typography variant="body1" style={{ fontSize: 8 }}>Fee: {x.feeNQT} DWA</Typography>
                                                        </Stack>
                                                        <Stack direction={"row"} justifyContent={"center"} sx={{ minWidth: 50 }}>
                                                            <Stack direction={"column"} style={{ marginTop: -3 }}>
                                                                <Typography style={{ fontWeight: "500", fontSize: 10, padding: 5, backgroundColor: green[100], borderRadius: 5 }}><span style={{ color: green[900] }}>{x.status}</span></Typography>
                                                                <Typography style={{ fontWeight: "500", fontSize: 10, padding: 5, backgroundColor: grey[100], color: grey[800], borderRadius: 5 }}><span style={{ color: green[900] }}>{formatRupiah(x.confirmations)} Confirmation</span></Typography>

                                                            </Stack>
                                                        </Stack>
                                                    </Stack>
                                                    <Divider sx={{ mt: 1, mb: 1 }} />
                                                </Grid>
                                            </Grid>
                                        </>
                                    ) : <Box display="flex" justifyContent="center"
                                        alignItems="center"
                                        minHeight="50vh">
                                        <CircularProgress color="inherit" /> &nbsp;
                                        <Typography style={{ fontSize: 12, color: grey[600] }}>Getting data...</Typography>
                                    </Box>}
                            </Box>
                            <Stack>
                                <Button variant="contained" fullWidth sx={{ background: '#F8F1DC' }} style={{ color: 'black' }} onClick={() => navigate("/transaction")}>See More</Button>
                            </Stack>
                        </Box>
                    </Stack>
                </Grid>

            </Grid>

        </Box>
    );
}


export default Home;
