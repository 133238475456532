import React from "react";
import Page from '../@dwa/shared/page';
import Token from "../pages/token";
import TokenDetail from "../pages/token/detail";

const tokenRoutes = [
    {
        path: "/token",
        element: <Page component={Token} layout={"logged"}/>,
    },
    {
        path: "/token-detail/:asset",
        element: <Page component={TokenDetail} layout={"logged"}/>,
    },
    {
        path: "/token-detail/:asset/:tab",
        element: <Page component={TokenDetail} layout={"logged"}/>,
    }
];

export default tokenRoutes;