import logo from './logo.svg';
import './App.css';
import { BrowserRouter,Route, Routes } from "react-router-dom";
import router from './router';
import Layouts from './layouts';
import DwaApp from './@dwa/DwaApp';
import { LAYOUT_APP } from './@dwa/constant';
import AppRoutes from './AppRoutes';
import DwaAuthProvider from './@dwa/DwaAuthProvider';

function App() {
  return (
    <BrowserRouter>
      <DwaApp activeLayout={LAYOUT_APP.DEFAULT}>
        <Layouts>
          <DwaAuthProvider>
          <AppRoutes/>
          </DwaAuthProvider>
        </Layouts>
      </DwaApp>
    </BrowserRouter>
  );
}

export default App;
