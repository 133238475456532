import React from "react";
import Page from '../@dwa/shared/page';
import AccountDetail from "../pages/account/detail";

const accountRoutes = [
    {
        path: "/account-detail/:account",
        element:<Page component={AccountDetail} layout={"logged"}/>,
    },
    {
        path: "/account-detail/:account/:tab",
        element:<Page component={AccountDetail} layout={"logged"}/>,
    }
];

export default accountRoutes;