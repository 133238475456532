import * as React from "react";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import dashboardRoutes from "./dashboardRoutes";
import aboutRoutes from "./aboutRoutes";
import homeRoutes from "./homeRoutes";
import transactionRoutes from "./transactionRoutes";
import blocksRoutes from "./blocksRoutes";
import tokenRoutes from "./tokenRoutes";
import accountRoutes from "./accountRoutes";
import commonRoutes from "./commonRoutes";
import dwaCoinReserveRoutes from "./dwaCoinReserveRoute";

const router = [
    {
    path: "/",
    element: <Navigate to={"/home"} />
    },
    ...commonRoutes,
    ...dashboardRoutes,
    ...aboutRoutes,
    ...homeRoutes,
    ...transactionRoutes,
    ...blocksRoutes,
    ...tokenRoutes,
    ...accountRoutes,
    ...dwaCoinReserveRoutes
];

const authenticatedRoutes = [
  ...homeRoutes,
  ...transactionRoutes,
  ...blocksRoutes,
  ...tokenRoutes,
  ...accountRoutes,
  ...dwaCoinReserveRoutes
];

const publicRoutes = [
  ...dashboardRoutes,
  ...aboutRoutes,
];

export {router as default,publicRoutes,authenticatedRoutes};


