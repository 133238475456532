import React, { useState, useEffect } from "react";
import { Box, Button, Card, CircularProgress, Divider, Grid, Stack, TextField, Typography } from "@mui/material";
import { CardMedia } from '@mui/material';
import { blue, green, grey } from "@mui/material/colors";
import { styled } from '@mui/system';
import axios from 'axios';
import moment from 'moment';
import ForwardIcon from '@mui/icons-material/Forward';
import TablePagination, {
    tablePaginationClasses as classes,
} from '@mui/base/TablePagination';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { useNavigate } from "react-router-dom";
import { formatRupiah, formatToken, formatTokenDecimals } from "../../../helpers/GeneralFunction";
import { CustomPagination } from "../../../component/pagination/customPagination";

const CustomTablePagination = styled(TablePagination)(
    ({ theme }) => `
    & .${classes.spacer} {
      display: none;
    }
  
    & .${classes.toolbar}  {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  
      @media (min-width: 768px) {
        flex-direction: row;
        align-items: center;
      }
    }
  
    & .${classes.selectLabel} {
      margin: 0;
    }
  
    & .${classes.select}{
      padding: 2px 6px;
      border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
      border-radius: 50px;
      background-color: transparent;
      color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  
      &:hover {
        background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
      }
  
      &:focus {
        outline: 1px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
      }
    }
  
    & .${classes.displayedRows} {
      margin: 0;
  
      @media (min-width: 768px) {
        margin-left: auto;
      }
    }
  
    & .${classes.actions} {
      padding: 2px;
      border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
      border-radius: 50px;
      text-align: center;
    }
  
    & .${classes.actions} > button {
      margin: 0 8px;
      border: transparent;
      border-radius: 4px;
      background-color: transparent;
      color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  
      &:hover {
        background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
      }
  
      &:focus {
        outline: 1px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
      }
    }
    `,
);

const TokenDetailTabTransaction = ({ assetTransfers, isLoaded, page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, dataLength }) => {

    const navigate = useNavigate();

    return (
        <>
            <Box style={{ width: "100%", overflowX: 'auto' }}>
                <Grid container>
                    <Grid item md={30} xs={30} sx={{ mt: 1, mb: 1 }}>
                        <Stack direction={{ md: "row", xs: "row" }} spacing={{ xs: 1, md: 2 }}>
                            <Stack direction={"row"} sx={{ minWidth: 170 }}>
                                <Typography style={{ fontWeight: "800", fontSize: 14 }}>Transaction</Typography>
                            </Stack>
                            <Stack direction={"row"} justifyContent={"flex-start"} sx={{ minWidth: 90 }}>
                                <Typography style={{ fontWeight: "800", fontSize: 14 }}>Block</Typography>
                            </Stack>
                            <Stack direction={"row"} justifyContent={"flex-start"} sx={{ minWidth: 150 }}>
                                <Typography style={{ fontWeight: "800", fontSize: 14 }}>Date & Time</Typography>
                            </Stack>
                            <Stack direction={"row"} justifyContent={"flex-start"} sx={{ minWidth: 200 }}>
                                <Typography style={{ fontWeight: "800", fontSize: 14 }}>Sender & Recipient</Typography>
                            </Stack>
                            <Stack direction={"row"} justifyContent={"flex-start"} sx={{ minWidth: 180 }}>
                                <Typography style={{ fontWeight: "800", fontSize: 14 }}>DWA Amount</Typography>
                            </Stack>
                            <Stack direction={"row"} justifyContent={"flex-start"} sx={{ minWidth: 100 }}>
                                <Typography style={{ fontWeight: "800", fontSize: 14 }}>Token</Typography>
                            </Stack>
                            <Stack direction={"row"} justifyContent={"flex-start"} sx={{ minWidth: 170 }}>
                                <Typography style={{ fontWeight: "800", fontSize: 14 }}>Token Amount</Typography>
                            </Stack>
                            <Stack direction={"row"} justifyContent={"flex-start"} sx={{ minWidth: 90 }}>
                                <Typography style={{ fontWeight: "800", fontSize: 14 }}>Status</Typography>
                            </Stack>
                        </Stack>
                        <Divider sx={{ mt: 1, mb: 1 }} />
                    </Grid>
                </Grid>


                {!isLoaded && assetTransfers ?
                    assetTransfers.map((x, i) =>
                        <>
                            <Grid container key={i}>
                                <Grid item md={30} xs={30} sx={{ mt: 1, mb: 1 }}>
                                    <Stack direction={"row"} spacing={{ xs: 1, md: 2 }}>
                                        <Stack direction={"row"} sx={{ minWidth: 170 }}>
                                            <a href="#" style={{ textDecoration: 'none' }}><Typography style={{ fontWeight: "500", fontSize: 12, color: blue[500] }} onClick={() => navigate("/transaction-detail/" + x.transaction)}>{x.transaction}</Typography></a>
                                            <Button variant="text" style={{ minWidth: 10, height: 20 }} onClick={() => { navigator.clipboard.writeText(x.transaction) }}>
                                                <FileCopyIcon style={{ color: "#DBBA52", fontSize: 16 }} />
                                            </Button>
                                        </Stack>
                                        <Stack direction={"row"} justifyContent={"flex-start"} sx={{ minWidth: 90 }}>
                                            <Typography style={{ fontWeight: "500", fontSize: 12 }}>{formatToken(x.ecBlockHeight)}</Typography>
                                        </Stack>
                                        <Stack direction={"row"} justifyContent={"flex-start"} sx={{ minWidth: 150 }}>
                                            <Typography style={{ fontWeight: "500", fontSize: 12 }}>{moment(x.timestamp).format("YYYY-MM-DD hh:mm:ss")}</Typography>
                                        </Stack>
                                        <Stack direction={"column"} justifyContent={"flex-start"} sx={{ minWidth: 200 }}>
                                            <Stack direction={"row"} spacing={3} justifyContent={"space-between"}>
                                                <span style={{ color: grey[500], fontSize: 12 }}>From </span>
                                                <a href="#" style={{ textDecoration: "none" }}><Typography style={{ fontWeight: "500", fontSize: 12, color: blue[500] }} onClick={() => navigate("/account-detail/" + x.sender)}>{x.sender_tag !== "" ? x.sender_tag : x.sender}</Typography></a>
                                            </Stack>
                                            <Stack direction={"row"} spacing={3} justifyContent={"space-between"}>
                                                <span style={{ color: grey[500], fontSize: 12 }}>To </span>
                                                <a href="#" style={{ textDecoration: "none" }}><Typography style={{ fontWeight: "500", fontSize: 12, color: blue[500] }} onClick={() => navigate("/account-detail/" + x.recipient)}>{x.recipient_tag !== "" ? x.recipient_tag : x.recipient}</Typography></a>
                                            </Stack>
                                        </Stack>
                                        <Stack direction={"column"} justifyContent={"flex-start"} sx={{ minWidth: 180 }}>
                                            <Stack direction={"row"} spacing={3} justifyContent={"space-between"} style={{ marginRight: 20 }}>
                                                <span style={{ color: grey[500], fontSize: 12 }}>Amount </span>
                                                <Typography style={{ fontWeight: "800", fontSize: 12 }}>{formatTokenDecimals(x.amountNQT, 3)}</Typography>
                                            </Stack>
                                            <Stack direction={"row"} spacing={3} justifyContent={"space-between"} style={{ marginRight: 20 }}>
                                                <span style={{ color: grey[500], fontSize: 12 }}>Fee </span>
                                                <Typography style={{ fontWeight: "800", fontSize: 12 }}>{formatTokenDecimals(x.feeNQT, 3)}</Typography>
                                            </Stack>
                                        </Stack>
                                        <Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} sx={{ minWidth: 100 }}>
                                            {
                                                x.attachment.asset_imagepath ?
                                                    <img src={x.attachment.asset_imagepath} style={{ width: 25, height: 25, borderRadius: 50 }} />
                                                    : null
                                            }
                                            <Typography style={{ fontWeight: "800", fontSize: 12, marginLeft: 10 }}>{x.attachment.asset_description}</Typography>
                                        </Stack>
                                        <Stack direction={"column"} justifyContent={"flex-start"} sx={{ minWidth: 170 }}>
                                            <Stack direction={"row"} spacing={3} justifyContent={"space-between"} style={{ marginRight: 20 }}>
                                                <span style={{ color: grey[500], fontSize: 12 }}>Amount </span>
                                                <Typography style={{ fontWeight: "800", fontSize: 12 }}>{formatTokenDecimals(x.attachment.quantityQNT, 3)}</Typography>
                                            </Stack>
                                            <Stack direction={"row"} spacing={3} justifyContent={"space-between"} style={{ marginRight: 20 }}>
                                                <span style={{ color: grey[500], fontSize: 12 }}>Fee </span>
                                                <Typography style={{ fontWeight: "800", fontSize: 12 }}>{formatTokenDecimals(x.attachment.feeQNT, 3)}</Typography>
                                            </Stack>
                                        </Stack>
                                        <Stack direction={"row"} justifyContent={"flex-start"} sx={{ minWidth: 90 }}>
                                            <Stack direction={"column"} style={{ marginTop: -3 }}>
                                                <Typography style={{ fontWeight: "500", fontSize: 10, padding: 5, backgroundColor: green[100], borderRadius: 5, width: 50 }}><span style={{ color: green[900] }}>{x.status}</span></Typography>
                                                <Typography style={{ fontWeight: "500", fontSize: 10, padding: 5, backgroundColor: grey[100], color: grey[800], borderRadius: 5, width: 'auto' }}><span style={{ color: grey[500] }}>{formatRupiah(x.confirmations)} Confirmations</span></Typography>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                    <Divider sx={{ mt: 1, mb: 1 }} />
                                </Grid>
                            </Grid>

                        </>
                    )
                    : <Box display="flex" justifyContent="center"
                        alignItems="center"
                        minHeight="50vh">
                        <CircularProgress color="inherit" /> &nbsp;
                        <Typography style={{ fontSize: 12, color: grey[600] }}>Getting data...</Typography>
                    </Box>}

            </Box>
            <Stack>
                <table aria-label="custom pagination table">
                    <tfoot>
                        <tr>
                            <CustomTablePagination
                                rowsPerPageOptions={[5, 10, 25, 50]}
                                colSpan={3}
                                count={20}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                slotProps={{
                                    select: {
                                        'aria-label': 'Show',
                                    },
                                    actions: {
                                        showFirstButton: false,
                                        showLastButton: false,
                                    }

                                }}
                                sx={{
                                    ".MuiTablePagination-actions .MuiTablePagination-actions":{
                                        opacity: 0
                                    },
                                    ".MuiTablePagination-actions":{
                                        opacity: 0
                                    },
                                    ".MuiTablePagination-displayedRows": {
                                        opacity: 0
                                    },
                                    ".MuiTablePagination-selectLabel": {
                                        opacity: 1
                                    }
                                }}
                                onPageChange={rowsPerPage >= dataLength ? handleChangePage : null}
                                onRowsPerPageChange={rowsPerPage <= dataLength ? handleChangeRowsPerPage : null}
                            />
                            <CustomPagination reachLimit={dataLength < rowsPerPage | dataLength === 0? true: false} handleChangePage={handleChangePage} />
                        </tr>
                    </tfoot>
                </table>
            </Stack>
        </>
    );
}


export default TokenDetailTabTransaction;