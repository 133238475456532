import React from "react";
import Header from "./header";
import { Box } from "@mui/material";
import { useTheme,ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Footer from "./footer";
import HeaderTransaction from "./headerTransaction";
import useDwaApp from '../hooks/useDwaApp';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const Layouts = ({children}) =>{
    const {activeLayout} = useDwaApp();

    const theme = useTheme();
    return (
      // <React.Fragment>
        <Box sx={{
            bgcolor: 'black',
            color: 'text.primary', 
            minHeight: '100vh',
            maxWidth: '100%',
            overflowX:'hidden'
          }}>
            {activeLayout === 'default' ?  <Header />  : null}
            {activeLayout === 'logged' ? <HeaderTransaction/> : null}
              <Box sx={{pt:10}}>
                  {children}
              </Box>
            <Footer/>
        </Box>
      // </React.Fragment>
    );
}

export default Layouts;