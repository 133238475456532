import React from 'react';
import useDwaApp from '../../../hooks/useDwaApp';

const Page = ({component, layout, ...restProps}) => {
    const {activeLayout, setActiveLayout} = useDwaApp();

    React.useEffect(() => {
        if (layout !== activeLayout) {
            setActiveLayout(layout);
        }
    }, [layout]);

    const PageComponent = component;

    return (
        <PageComponent {...restProps} />
    );
};

export default Page;