import React, { useState, useEffect } from "react";
import { Box, Button, Card, CircularProgress, Divider, Grid, Stack, TextField, Typography } from "@mui/material";
import { CardMedia } from '@mui/material';
import { blue, grey } from "@mui/material/colors";
import { styled } from '@mui/system';
import axios from 'axios';
import moment from 'moment';
import ForwardIcon from '@mui/icons-material/Forward';
import TablePagination, {
  tablePaginationClasses as classes,
} from '@mui/base/TablePagination';
import { useNavigate } from "react-router-dom";
import { formatTokenDecimals } from "../../helpers/GeneralFunction";
import FileCopyIcon from '@mui/icons-material/FileCopy';


const CustomTablePagination = styled(TablePagination)(
  ({ theme }) => `
    & .${classes.spacer} {
      display: none;
    }
  
    & .${classes.toolbar}  {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  
      @media (min-width: 768px) {
        flex-direction: row;
        align-items: center;
      }
    }
  
    & .${classes.selectLabel} {
      margin: 0;
    }
  
    & .${classes.select}{
      padding: 2px 6px;
      border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
      border-radius: 50px;
      background-color: transparent;
      color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  
      &:hover {
        background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
      }
  
      &:focus {
        outline: 1px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
      }
    }
  
    & .${classes.displayedRows} {
      margin: 0;
  
      @media (min-width: 768px) {
        margin-left: auto;
      }
    }
  
    & .${classes.actions} {
      padding: 2px;
      border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
      border-radius: 50px;
      text-align: center;
    }
  
    & .${classes.actions} > button {
      margin: 0 8px;
      border: transparent;
      border-radius: 4px;
      background-color: transparent;
      color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  
      &:hover {
        background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
      }
  
      &:focus {
        outline: 1px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
      }
    }
    `,
);

const Token = () => {

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [tokenList, setTokenList] = useState([]);
  const [isLoaded, setIsloaded] = useState(true);
  const navigate = useNavigate();

  const getTokenList = () => {
    return new Promise((resolve, reject) => {
      axios.post(process.env.REACT_APP_BASE_URL + '/api/asset/list/v01', {
        secretKey: process.env.REACT_APP_SECRET,
        rpp: 10,
        page_no: 1
      })
        .then((response) => {
          let res = response.data;
          console.log(res);
          setTokenList(res.result.assetList);
          resolve(res);
        }).catch((err) => {
          reject(err);
          console.log(err);
        }).finally(() => {
          setIsloaded(false);
        });
    });
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const formatRupiah = (angka = 0, prefix) => {
    var number_string = angka.toString().replace(/[^,\d]/g, ''),
      split = number_string.split(','),
      sisa = split[0].length % 3,
      rupiah = split[0].substr(0, sisa),
      ribuan = split[0].substr(sisa).match(/\d{3}/gi);

    if (ribuan) {
      var separator = sisa ? '.' : '';
      rupiah += separator + ribuan.join('.');
    }
    rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah;
    return prefix === undefined ? rupiah : prefix + ' ' + rupiah;
  };

  const converter = (num, decimal) => {
    // let text = formatRupiah(num);
    return parseInt(num).toFixed(decimal).replace(".", ",");
  }

  const initialization = async () => {
    await getTokenList();
  }

  useEffect(() => {
    initialization().catch((err) => console.log(err));
  }, []);

  return (
    <Box>
      <Grid container bgcolor={'white'} spacing={0} sx={{ p: 5 }}>

        <Grid item md={12} xs={12} sx={{ pt: 10 }}>
          <Box sx={{ border: 0.5, borderColor: 'gray', p: 1, borderRadius: 2 }}>
            <Grid container>
              <Grid item md={12}>Tokens</Grid>
            </Grid>
            <Divider sx={{ mt: 1, mb: 1 }} />
            <Box sx={{ overflowX: 'auto' }}>
              <Grid container>
                <Grid item md={12} xs={28} sx={{ mt: 1, mb: 1 }}>
                  <Stack direction={"row"} spacing={{ xs: 1, md: 4 }}>
                  <Stack direction={"row"} justifyContent={"flex-start"} sx={{ minWidth: 200 }}>
                      <Typography style={{ fontWeight: "800", fontSize: 14 }}>Token Name</Typography>
                    </Stack>
                    <Stack direction={"row"} justifyContent={"flex-start"} sx={{ minWidth: 200 }}>
                      <Typography style={{ fontWeight: "800", fontSize: 14 }}>Token Code</Typography>
                    </Stack>
                  
                    <Stack direction={"row"} justifyContent={"flex-start"} sx={{ minWidth: 200 }}>
                      <Typography style={{ fontWeight: "800", fontSize: 14 }}>Total Supply</Typography>
                    </Stack>
                    <Stack direction={"row"} justifyContent={"flex-start"} sx={{ minWidth: 200 }}>
                      <Typography style={{ fontWeight: "800", fontSize: 14 }}>Creator</Typography>
                    </Stack>
                    {/* <Stack direction={"row"} justifyContent={"flex-start"} sx={{ minWidth: 200 }}>
                      <Typography style={{ fontWeight: "800", fontSize: 14 }}>Creator</Typography>
                    </Stack> */}
                  </Stack>
                  <Divider sx={{ mt: 1, mb: 1 }} />
                </Grid>
              </Grid>
              {!isLoaded && tokenList.length > 0 ?

                tokenList.map((x, i) =>
                  <>
                    <Grid container key={i}>
                      <Grid item md={12} xs={28} sx={{ mt: 1, mb: 1 }}>
                        <Stack direction={"row"} alignItems={"center"}   spacing={{ xs: 1, md: 4 }}>
                       
                     
                          <Stack direction={"row"} alignItems={"center"} sx={{ minWidth: 200 }}>
                            <img src={x.asset_imagepath} style={{ height: 35, width: 35 }} />
                            <a href="#" style={{ textDecoration: "none" }}><Typography sx={{ ml: 1 }} style={{ fontWeight: "800", fontSize: 14 }} onClick={() => navigate(`/token-detail/${x.asset}`)}>{x.name}</Typography></a>
                          </Stack>
                          <Stack direction={"row"} alignContent={'center'} sx={{ minWidth: 200 }}>
                          <a href="#" style={{ textDecoration: "none" }}><Typography color={grey[500]} style={{ fontSize: 12, color: blue[500] }} onClick={() => navigate(`/token-detail/${x.asset}`)}>{x.asset}</Typography></a>
                            <Button variant="text" style={{ minWidth: 10, height: 20 }} onClick={() => { navigator.clipboard.writeText(x.asset) }}>
                              <FileCopyIcon style={{ color: "#DBBA52", fontSize: 16 }} />
                            </Button>
                          </Stack>
                          <Stack direction={"column"} sx={{ minWidth: 200 }}>
                            <Typography color={grey[500]} style={{ fontSize: 12 }}>{formatTokenDecimals(x.initialQuantityQNT, x.decimals)}</Typography>
                          </Stack>
                          <Stack direction={"row"} alignContent={'center'} sx={{ minWidth: 200 }}>
                            <a href="#" style={{ textDecoration: "none" }}><Typography color={grey[500]} style={{ fontSize: 12, color: blue[500] }} onClick={() => navigate(`/account-detail/${x.account}`)}>{x.account}</Typography></a>
                            <Button variant="text" style={{ minWidth: 10, height: 20 }} onClick={() => { navigator.clipboard.writeText(x.account) }}>
                              <FileCopyIcon style={{ color: "#DBBA52", fontSize: 16 }} />
                            </Button>
                          </Stack>
                        </Stack>
                        <Divider sx={{ mt: 1, mb: 1 }} />
                      </Grid>
                    </Grid>

                  </>

                )

                : <Box display="flex" justifyContent="center"
                  alignItems="center"
                  minHeight="50vh">
                  <CircularProgress color="inherit" /> &nbsp;
                  <Typography style={{ fontSize: 12, color: grey[600] }}>Getting data...</Typography>
                </Box>}
            </Box>
            <Stack>
              <table aria-label="custom pagination table">
                <tfoot>
                  <tr>
                    {/* <CustomTablePagination
                      rowsPerPageOptions={[5, 10, 25, { label: '100', value: 100 }]}
                      colSpan={3}
                      count={tokenList.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      slotProps={{
                        select: {
                          'aria-label': 'Show',
                        },
                        actions: {
                          showFirstButton: true,
                          showLastButton: true,
                        },
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    /> */}
                  </tr>
                </tfoot>
              </table>
            </Stack>
          </Box>
        </Grid>

      </Grid>
    </Box>
  );
}


export default Token;