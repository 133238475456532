import React from 'react';
import routes from './router'
import useDwaRoutes from './hooks/useDwaRoutes';

const AppRoutes = () => {
    const appRoutes = useDwaRoutes(routes);
    
    return (
        <React.Fragment>
            {appRoutes}
        </React.Fragment>
        )
};

export default AppRoutes;



