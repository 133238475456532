import React from "react";
import Grid from '@mui/material/Grid';
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Header = () =>{
    const navigate = useNavigate();

    return (
        <React.Fragment>
            <Box width={'100%'} height={80} 
                   bgcolor={'black'}  style={{position:'fixed'}}>
                <Grid container sx={{pt:2}}>
                    <Grid item md={3} sx={{pl:5}}>
                        <img src={'/images/Logo.png'} />
                    </Grid>
                    <Grid item md={9}>
                        <Grid container sx={{pt:1}}>
                            <Grid item md={1}>
                                <Typography style={{color:'white'}} onClick={()=>navigate("/home")}>Home</Typography>
                            </Grid>
                            <Grid item md={2}>
                                <Typography style={{color:'white'}}>Privacy & Terms</Typography>
                            </Grid>
                            <Grid item md={1.5}>
                                <Typography style={{color:'white'}}>Repository</Typography>
                            </Grid>
                            <Grid item md={1.5}>
                                <Typography style={{color:'white'}}>Explorer</Typography>
                            </Grid>
                            <Grid item md={1.5}>
                                <Typography style={{color:'white'}}>Community</Typography>
                            </Grid>
                            <Grid item md={1.5}>
                                <Typography style={{color:'white'}}>Whitepaper</Typography>
                            </Grid>
                            <Grid item md={1.5}>
                                <Typography style={{color:'white'}}>API</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </React.Fragment>
    );
}

export default Header;