import React from "react";
import Dashboard from '../pages/dashboard';

const dashboardRoutes = [
    {
        path: "/dashboard",
        element: <Dashboard/>,
    }
];

export default dashboardRoutes;