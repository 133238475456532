import React from "react";
import Page from '../@dwa/shared/page';

import TokenDetail from "../pages/token/detail";
import DwaCoinReserve from "../pages/dwacoinreserve";

const dwaCoinReserveRoutes = [
    {
        path: "/dwa-coin-reserve",
        element: <Page component={DwaCoinReserve} layout={"logged"}/>,
    }
];

export default dwaCoinReserveRoutes;