import React, { useState,useEffect, useCallback } from "react";
import { Box, Button, Card, CircularProgress, Divider, Grid, Stack, TextField, Typography } from "@mui/material";
import { CardMedia } from '@mui/material';
import { blue, grey } from "@mui/material/colors";
import {styled} from '@mui/system';
import axios from 'axios';
import moment from 'moment';
import ForwardIcon from '@mui/icons-material/Forward';
import {useParams} from 'react-router-dom';
import TablePagination, {
    tablePaginationClasses as classes,
  } from '@mui/base/TablePagination';
import { useNavigate } from "react-router-dom";
import { formatTokenDecimals } from "../../../helpers/GeneralFunction";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';


  const CustomTablePagination = styled(TablePagination)(
    ({ theme }) => `
    & .${classes.spacer} {
      display: none;
    }
  
    & .${classes.toolbar}  {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  
      @media (min-width: 768px) {
        flex-direction: row;
        align-items: center;
      }
    }
  
    & .${classes.selectLabel} {
      margin: 0;
    }
  
    & .${classes.select}{
      padding: 2px 6px;
      border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
      border-radius: 50px;
      background-color: transparent;
      color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  
      &:hover {
        background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
      }
  
      &:focus {
        outline: 1px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
      }
    }
  
    & .${classes.displayedRows} {
      margin: 0;
  
      @media (min-width: 768px) {
        margin-left: auto;
      }
    }
  
    & .${classes.actions} {
      padding: 2px;
      border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
      border-radius: 50px;
      text-align: center;
    }
  
    & .${classes.actions} > button {
      margin: 0 8px;
      border: transparent;
      border-radius: 4px;
      background-color: transparent;
      color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  
      &:hover {
        background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
      }
  
      &:focus {
        outline: 1px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
      }
    }
    `,
  );

const NotFound = () =>{
    const {index} = useParams();
    const [tokenDetail,setTokenDetail] = useState([]);
    const [isLoaded,setIsloaded] = useState(true);
    const [assetTransfers,setAssetTransfers] = useState([]);
    const [assetAccount,setAssetAccount] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const navigate = useNavigate();

    

    useEffect(()=>{
            
      
    },[]);

    return(
        <Box>
            <Grid container bgcolor={'white'} spacing={0} sx={{p:5,pt:10}}>
                <Stack direction={"row"} alignItems={'center'} sx={{pb:3}} style={{cursor:'pointer'}} onClick={()=>navigate("/home")}>
                    <a href="#" style={{textDecoration: 'none',color:'black'}}><ArrowBackIosIcon style={{fontSize:14}}/> Back</a>
                </Stack>
                <Grid container spacing={2}  sx={{pt:2}}>
                    <Grid item md={12} xs={12}>
                        <Box sx={{ border: 0.5,borderColor:'gray',p:1,borderRadius:2 }}>
                    
                            <Grid container>
                                <Grid item md={12}><strong>Search for</strong> #{index}</Grid>
                            </Grid>
                            <Divider sx={{mt:1,mb:1}}/>
                            <Stack direction={"column"} alignItems="center">
                                <img src={'/images/notfound.png'} style={{width:'30vw'}}/>
                            </Stack>
                            <Stack direction={"column"} alignItems="center" sx={{pt:4}}>
                                <Typography variant="h4" style={{fontWeight:'800'}}>Search Not Found</Typography>
                                <label style={{color:grey[500],width:'50vw',textAlign:'center',fontSize:14,paddingTop:10}}>We couldn't find any results for your search, Double-check the spelling of your search query. Sometimes a simple typo can lead to no results.</label>
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>
                
            </Grid>
        </Box>
    );
}


export default NotFound;