import React, { useState,useEffect } from "react";
import { Box, Button, Card, CircularProgress, Divider, Grid, Stack, TextField, Typography } from "@mui/material";
import { CardMedia } from '@mui/material';
import { blue, grey } from "@mui/material/colors";
import {styled} from '@mui/system';
import axios from 'axios';
import moment from 'moment';
import ForwardIcon from '@mui/icons-material/Forward';

import TablePagination, {
    tablePaginationClasses as classes,
  } from '@mui/base/TablePagination';
import { useNavigate } from "react-router-dom";


  const CustomTablePagination = styled(TablePagination)(
    ({ theme }) => `
    & .${classes.spacer} {
      display: none;
    }
  
    & .${classes.toolbar}  {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  
      @media (min-width: 768px) {
        flex-direction: row;
        align-items: center;
      }
    }
  
    & .${classes.selectLabel} {
      margin: 0;
    }
  
    & .${classes.select}{
      padding: 2px 6px;
      border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
      border-radius: 50px;
      background-color: transparent;
      color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  
      &:hover {
        background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
      }
  
      &:focus {
        outline: 1px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
      }
    }
  
    & .${classes.displayedRows} {
      margin: 0;
  
      @media (min-width: 768px) {
        margin-left: auto;
      }
    }
  
    & .${classes.actions} {
      padding: 2px;
      border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
      border-radius: 50px;
      text-align: center;
    }
  
    & .${classes.actions} > button {
      margin: 0 8px;
      border: transparent;
      border-radius: 4px;
      background-color: transparent;
      color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  
      &:hover {
        background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
      }
  
      &:focus {
        outline: 1px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
      }
    }
    `,
  );

const AccountDetailTabToken = ({assetAccount,isLoaded}) =>{
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    // const [isLoaded,setIsloaded] = useState(true);
    const navigate = useNavigate();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
    // const handleChangeRowsPerPage = (event) => {
    // setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(0);
    // };

    // const initialization = async () =>{
    //     await getAssetTransfer();
    // }

    // useEffect(()=>{
    //     initialization().catch((err)=>console.log(err));
    // },[]);

    return(
        <Box>
           <Box style={{overflowX:'auto'}}>
            <Grid container>
                <Grid md={12} xs={28}  item sx={{mt:1,mb:1}}>
                    <Stack direction={"row"} spacing={{xs:1,md:2}}>
                        <Stack direction={"row"} sx={{ minWidth:200 }}>
                            <Typography style={{fontWeight:"800",fontSize:14}}>Token Name</Typography>
                        </Stack>
                        <Stack direction={"row"} justifyContent={"flex-start"} sx={{ minWidth:200 }}>
                            <Typography style={{fontWeight:"800",fontSize:14}}>Description</Typography>
                        </Stack>
                        <Stack direction={"row"} justifyContent={"flex-start"} sx={{ minWidth:200 }}>
                            <Typography style={{fontWeight:"800",fontSize:14}}>Balance</Typography>
                        </Stack>
                        {/* <Stack direction={"row"} justifyContent={"flex-start"} sx={{ minWidth:200 }}>
                            <Typography style={{fontWeight:"800",fontSize:14}}>Account RS</Typography>
                        </Stack> */}
                    </Stack>
                    
            <Divider sx={{mt:1,mb:1}}/>
                </Grid>
            </Grid>

            {!isLoaded && assetAccount ?
            (rowsPerPage > 0
                ? assetAccount.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : assetAccount
            ).map((x,i)=>
            <>
            <Grid container key={i}>
            <Grid item md={12} xs={28} sx={{mt:1,mb:1}}>
                    <Stack direction={"row"} spacing={{xs:1,md:2}} alignItems={"center"}>
                        <Stack direction={"row"} alignItems={"center"} sx={{ minWidth:200 }}>
                            <img src={x.asset_imagepath} style={{height:35,width:35}}/>
                            <Typography sx={{ml:1}} style={{fontWeight:"800",fontSize:14}} onClick={()=>navigate(`/token-detail/${x.asset}`)}>{x.asset_name}</Typography>
                        </Stack>
                        <Stack direction={"row"} justifyContent={"flex-start"} sx={{ minWidth:200 }}>
                            <Typography style={{fontWeight:"500",fontSize:12}}>{x.asset_description}</Typography>
                        </Stack>
                        <Stack direction={"row"} justifyContent={"flex-start"} sx={{ minWidth:200 }}>
                            <Typography style={{fontWeight:"500",fontSize:12}}>{x.unconfirmedQuantityQNT}</Typography>
                        </Stack>
                        {/* <Stack direction={"row"} justifyContent={"flex-start"} sx={{ minWidth:200 }}>
                            <Typography style={{fontWeight:"500",fontSize:12,color:blue[500]}}>{x.asset}</Typography>
                        </Stack> */}
                    </Stack>
                    
            <Divider sx={{mt:1,mb:1}}/>
                </Grid>
            </Grid>
            </>
            )
            : <Box display="flex" justifyContent="center"
            alignItems="center"
            minHeight="50vh">
                    <CircularProgress color="inherit"/> &nbsp;
                    <Typography style={{fontSize:12,color:grey[600]}}>Getting data...</Typography>
                </Box> }
                </Box>
            <Stack>
                <table aria-label="custom pagination table">
                    <tfoot>
                    {/* <tr>
                        <CustomTablePagination
                        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                        colSpan={3}
                        count={assetAccount ? assetAccount.length : 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        slotProps={{
                            select: {
                            'aria-label': 'Show',
                            },
                            actions: {
                            showFirstButton: true,
                            showLastButton: true,
                            },
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </tr> */}
                    </tfoot>
                </table>
            </Stack>
            </Box>
    );
}


export default AccountDetailTabToken;