import React from "react";
import Grid from '@mui/material/Grid';
import { Box, Divider, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
const Footer = () =>{
    const navigate = useNavigate();

    return (
            <Box style={{backgroundColor:'#1F2A37',width:'100vw'}}>
                        <Box display="flex" alignItems="center"
                            justifyContent="center" style={{height:200, width:'100vw'}}>
                        <Grid container sx={{pt:2}} >
                            <Grid item md={8} xs={12} sx={{pl:5,pt:2, pr:5}}>
                                <Grid container>
                                    <Grid item md={12} >
                                        <img src={'/images/Logo.png'} />
                                    </Grid>
                                </Grid>
                                <Grid container sx={{mt:2, width:'100%'}}>
                                    <Grid item md={12}>
                                        <Typography variant="body1" color={'white'} style={{fontSize:12}} width={'100%'}>
                                            {'DWA is a 100% proof-of-stake cryptocurrency which designed to expand the use of blockchain technology beyond the simple transfer of value.'}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={4} xs={12} sx={{pl:5,pt:0, pr:5}}>
                                <Grid container sx={{pt:1}}  justifyContent="space-between">
                                    <Grid item md={4}>
                                    <a href="#" style={{textDecoration:'none'}}><Typography variant="body1" color={'white'} style={{fontSize:14}} onClick={()=>window.location.href = 'https://dwa.network/about'}>ABOUT DWA</Typography></a>
                                    </Grid>
                                    <Grid item md={4}>
                                    <a href="#" style={{textDecoration:'none'}}><Typography variant="body1" color={'white'} style={{fontSize:14}} onClick={()=>window.location.href = 'https://dwa.network/privacy-terms'}>
PRIVACY & TERMS</Typography></a>
                                    </Grid>
                                </Grid>
                                <Grid container sx={{pt:1}}  justifyContent="space-between">
                                    <Grid item md={4}>
                                    <a href="#" style={{textDecoration:'none'}}> <Typography variant="body1" color={'white'} style={{fontSize:14}} onClick={()=>window.location.href = 'https://github.com/dwacoin/dwa'}>REPOSITORY</Typography></a>
                                    </Grid>
                                    <Grid item md={4}>
                                    <a href="#" style={{textDecoration:'none'}}><Typography variant="body1" color={'white'} style={{fontSize:14}}>EXPLORER</Typography></a>
                                    </Grid>
                                </Grid>
                                <Grid container sx={{pt:1}}  justifyContent="space-between" alignItems="flex-start">
                                    <Grid item md={4}>
                                    <a href="#" style={{textDecoration:'none'}}><Typography variant="body1" color={'white'} style={{fontSize:14}} onClick={()=>window.location.href = 'https://whitepaper.dwa.network'}>WHITEPAPER</Typography></a>
                                    </Grid>
                                    <Grid item md={4}>
                                            <a href="#" style={{textDecoration:'none'}}><Typography variant="body1" color={'white'} style={{fontSize:14}} onClick={()=>window.location.href = 'https://api.dwa.network'}>API</Typography></a>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        </Box>
                    <Divider sx={{background:'gray',m:2, mt:5}} variant="middle"/>
                    <Grid container>
                        <Grid item md={12} xs={12} align="center" sx={{mb:2, mx:4}}>
                            <Stack direction={"row"} justifyContent={"space-between"}>
                            <Typography variant="body1" color={'white'} style={{fontSize:12}}>© DWA NETWORK 2023</Typography>
                            <Stack direction={"row"}>
                            <MailOutlineIcon style={{color:"white"}}/>&nbsp;<Typography style={{color:"#DBBA52",fontSize:12}} sx={{pt:0.5}}>info@dwa.network</Typography>
                           
                            </Stack>
                                   
                            </Stack>
                       
                        </Grid>
                    </Grid>
            </Box>
    );
}

export default Footer;