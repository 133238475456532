import React from "react";
import Page from '../@dwa/shared/page';
import NotFound from "../pages/common/notfound";

const commonRoutes = [
    {
        path: "/not-found/:index",
        element:<Page component={NotFound} layout={"logged"}/>,
    }
];

export default commonRoutes;