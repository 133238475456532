import React, { useEffect } from 'react';
import useRoutePathMatch from '../hooks/useRoutePathMatch';
import {authenticatedRoutes,publicRoutes} from '../router';
import { useLocation,useNavigate } from 'react-router-dom';

const DwaAuthProvider = ({children}) =>{
    const location = useLocation();
    const navigate = useNavigate();
    const authenticatedRoute = useRoutePathMatch(authenticatedRoutes,location.pathname);
    const publicRoute = useRoutePathMatch(publicRoutes,location.pathname);

    useEffect(()=>{
        // if(!localStorage.getItem("_logged")){
        //     //is not logged but match auth route
        //     if(authenticatedRoute){
        //         navigate("/login");
        //     }
        // }else{
        //     // is logged but match route public
        //     if(publicRoute){
        //         navigate("/home");
        //     }
        // }
        // navigate("/dashboard");
    },[]);

    return (
        <React.Fragment>
            {children}
        </React.Fragment>
    );
}

export default DwaAuthProvider;