import FileCopyIcon from '@mui/icons-material/FileCopy';
import { Tooltip } from '@mui/material';
import { useState } from 'react';
export const FileCopyButton = (param) => {
    const [isCopied, setIsCopied] = useState(false);
    // Fungsi untuk menangani klik tombol
    const handleClick = (props) => {
        setIsCopied(true);
        // Tambahkan logika lain yang diperlukan di sini
        // Misalnya, navigasi atau tindakan lainnya
        navigator.clipboard.writeText(props)
        // Setelah beberapa waktu, atur kembali isCopied menjadi false
        setTimeout(() => {
            setIsCopied(false);
        }, 1000); // Ubah ke nilai yang sesuai dengan kebutuhan Anda
    };
    return (
        <>
            <Tooltip title={!isCopied ? "Copy?" : "Text Copied!"} arrow>
                <button
                    style={{
                        border: 'none',
                        backgroundColor: 'transparent',
                        cursor: 'pointer',
                        '&:hover': { // Atur latar belakang menjadi abu-abu saat tombol dihover
                            backgroundColor: 'red'
                        },
                        transition: 'transform 0.2s ease'
                    }}
                    onMouseDown={(e) => e.currentTarget.style.transform = 'scale(0.95)'} // Scale down saat tombol ditekan
                    onMouseUp={(e) => e.currentTarget.style.transform = 'scale(1)'} // Kembalikan ke ukuran normal saat tombol dilepas 
                    onClick={()=>handleClick(param.param)}>
                    <FileCopyIcon style={{ color: "#DBBA52", fontSize: 16 }} />
                </button>
            </Tooltip>
        </>
    )
}