import React from "react";
import TransactionAll from '../pages/transactions/alltransactions';
import Page from '../@dwa/shared/page';
import DetailTransaction from "../pages/transactions/detail";

const transactionRoutes = [
    {
        path: "/transaction",
        element: <Page component={TransactionAll} layout={"logged"}/>,
    },
    {
        path: "/transaction-detail/:transactionid",
        element: <Page component={DetailTransaction} layout={"logged"}/>,
    }
];

export default transactionRoutes;