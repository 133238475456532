import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import { useLocation, useNavigate } from 'react-router-dom';
import { Grid, Stack } from '@mui/material';

export default function TemporaryDrawer() {

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const location = useLocation();
  const navigation = useNavigate();

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      
      style={{backgroundColor:'#1F2A37', height:'100%', color:"white"}}
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {['Home', 'Blocks', 'Transaction', 'Token', 'DWA Reserve Account'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton 
            style={{color:location.pathname.substring(1) === text.toLowerCase() ? "#D8B64A":'white',fontWeight:location.pathname.substring(1) === text.toLowerCase() ? "bold" :"lighter",fontSize:14}} 
            onClick={()=>navigation(text === 'DWA Reserve Account'? `/dwa-coin-reserve` : `/${text.toLowerCase()}`)}>
              {/* <ListItemIcon hidden> /dwa-coin-reserve
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon> */}
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      {/* {['left', 'right', 'top', 'bottom'].map((anchor) => ( */}
        <React.Fragment key={1}>
          <Stack direction="row" justifyContent="flex-end" alignItems={"center"}>
            <MenuIcon onClick={toggleDrawer("left", true)} style={{color:'white'}}/>
          </Stack>
            <Drawer
              anchor={"left"}
              open={state["left"]}
              onClose={toggleDrawer("left", false)}
            >
            {list("left")}
          </Drawer>
        </React.Fragment>
      {/* ))} */}
    </div>
  );
}