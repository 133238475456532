import {useRoutes} from "react-router-dom";
import {buildRoutes} from "../@dwa/utils/routeHelpers";
import useDwaApp from './useDwaApp';

let generatedRoutes = [];

const useDwaRoutes = (routes) => {
    const {rebuildRoutes} = useDwaApp();

    if(rebuildRoutes) {
        generatedRoutes = buildRoutes([...routes]);
    }
    return useRoutes(generatedRoutes);
};

export default useDwaRoutes;