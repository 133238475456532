import React from 'react';
import DwaAppContext from './DwaAppContext';

const DwaApp = (props) => {
    const [activeLayout, setActiveLayout] = React.useState(props.activeLayout);
    const [rebuildRoutes, setRebuildRoutes] = React.useState(true);

    React.useEffect(() => {
        if(rebuildRoutes) {
            setRebuildRoutes(false);
        }
    }, [rebuildRoutes]);

    const appContextValue = React.useMemo(() => {
        return {
            activeLayout,
            setActiveLayout,
            rebuildRoutes,
            setRebuildRoutes,
        }
    }, [activeLayout, setActiveLayout, rebuildRoutes, setRebuildRoutes]);

    return (
        <DwaAppContext.Provider value={appContextValue}>
            {props?.children}
        </DwaAppContext.Provider>
    );
};

export default DwaApp;