import React, { useState, useEffect, useCallback } from "react";
import { Box, Button, Card, CircularProgress, Divider, Grid, Stack, TextField, Typography } from "@mui/material";
import { CardMedia } from '@mui/material';
import { blue, grey } from "@mui/material/colors";
import { styled } from '@mui/system';
import axios from 'axios';
import moment from 'moment';
import TablePagination, {
  tablePaginationClasses as classes,
} from '@mui/base/TablePagination';
import { useNavigate } from "react-router-dom";
import { formatRupiah, formatToken, formatTokenDecimals } from "../../helpers/GeneralFunction";
import { CustomPagination } from "../../component/pagination/customPagination";
import { FileCopyButton } from "../../component/copyButton/fileCopyButton";


const CustomTablePagination = styled(TablePagination)(
  ({ theme }) => `
    & .${classes.spacer} {
      display: none;
    }
  
    & .${classes.toolbar}  {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  
      @media (min-width: 768px) {
        flex-direction: row;
        align-items: center;
      }
    }
  
    & .${classes.selectLabel} {
      margin: 0;
    }
  
    & .${classes.select}{
      padding: 2px 6px;
      border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
      border-radius: 50px;
      background-color: transparent;
      color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  
      &:hover {
        background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
      }
  
      &:focus {
        outline: 1px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
      }
    }
  
    & .${classes.displayedRows} {
      margin: 0;
  
      @media (min-width: 768px) {
        margin-left: auto;
      }
    }
  
    & .${classes.actions} {
      padding: 2px;
      border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
      border-radius: 50px;
      text-align: center;
    }
  
    & .${classes.actions} > button {
      margin: 0 8px;
      border: transparent;
      border-radius: 4px;
      background-color: transparent;
      color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  
      &:hover {
        background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
      }
  
      &:focus {
        outline: 1px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
      }
    }
    `,
);

const Blocks = () => {

  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [blocks, setBlocks] = useState([]);
  const [isLoaded, setIsloaded] = useState(true);
  const navigate = useNavigate();

  const getBlockList = useCallback((inc = 1) => {
    return new Promise((resolve, reject) => {
      axios.post(process.env.REACT_APP_BASE_URL + '/api/block/list/v01', {
        secretKey: process.env.REACT_APP_SECRET,
        rpp: 50,
        page_no: inc,
      })
        .then((response) => {
          let res = response.data;
          console.log(res);
          setBlocks(res.result.blockList);
          resolve(res);
        }).catch((err) => {
          reject(err);
          console.log(err);
        }).finally(() => {
          setIsloaded(false);
        });
    });
  }, [rowsPerPage, page]);

  const handleChangePage = async (props) => {
    const { type, increment } = props;
    if (type !== 'next' && page === 1) {

    }
    else {
      setPage(prevState => type === 'next' ? prevState + increment : prevState - increment);
      let state = type === 'next' ? page + 1 : page - 1;
      await getBlockList(state)
    }

  };
  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };
  const handleChangeRowsPerPage = async (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };
  const initialization = async () => {
    await getBlockList();
  }

  useEffect(() => {
    initialization().catch((err) => console.log(err));
  }, [getBlockList]);

  return (
    <Box>
      <Grid container bgcolor={'white'} spacing={0} sx={{ p: 5 }}>

        <Grid item md={12} xs={12} sx={{ pt: 10 }}>
          <Box sx={{ border: 0.5, borderColor: 'gray', p: 1, borderRadius: 2 }}>
            <Grid container>
              <Stack flex={1} justifyContent={'space-between'} direction={"row"} spacing={{ md: 3, xs: 2 }}>
                <Box direction={"row"} mid={12}>Blocks</Box>
                <Box direction={"row"} sx={12}>
                  <Typography> {`Page ` + page}</Typography>

                </Box>
              </Stack>
            </Grid>
            <Divider sx={{ mt: 1, mb: 1 }} />
            <Box sx={{ paddingX: 0, overflowX: 'auto' }}>
              <Grid container>
                <Grid item md={12} xs={28} sx={{ mt: 1, mb: 1 }}>
                  <Stack direction={"row"} spacing={{ xs: 1, md: 4 }}>
                    <Stack direction={"row"} justifyContent={"flex-start"} sx={{ minWidth: 180 }}>
                      <Typography style={{ fontWeight: "800", fontSize: 14 }}>Block</Typography>
                    </Stack>
                    <Stack direction={"row"} justifyContent={"flex-start"} sx={{ minWidth: 70 }}>
                      <Typography style={{ fontWeight: "800", fontSize: 14 }}>Height</Typography>
                    </Stack>
                    <Stack direction={"row"} justifyContent={"flex-start"} sx={{ minWidth: 160 }}>
                      <Typography style={{ fontWeight: "800", fontSize: 14 }}>Date & Time</Typography>
                    </Stack>
                    <Stack direction={"row"} justifyContent={"flex-start"} sx={{ minWidth: 150 }}>
                      <Typography style={{ fontWeight: "800", fontSize: 14 }}>Total Transaction</Typography>
                    </Stack>
                    <Stack direction={"row"} justifyContent={"flex-start"} sx={{ minWidth: 200 }}>
                      <Typography style={{ fontWeight: "800", fontSize: 14 }}>Generator</Typography>
                    </Stack>
                  </Stack>

                  <Divider sx={{ mt: 1, mb: 1 }} />
                </Grid>
              </Grid>
              {!isLoaded && blocks.length > 0 ?
                (rowsPerPage < blocks.length
                  ? blocks.slice(0, rowsPerPage)
                  : blocks
                ).map((x, i) =>
                  <>
                    <Grid container key={i}>
                      <Grid item md={12} xs={28} sx={{ mt: 1, mb: 1 }}>
                        <Stack direction={"row"} spacing={{ xs: 1, md: 4 }}>
                          <Stack direction={"row"} sx={{ minWidth: 180 }} alignContent={"center"} >
                            <a href="#" style={{ textDecoration: 'none' }}>
                              <Typography style={{ fontWeight: "500", fontSize: 12, color: blue[500] }} onClick={() => navigate("/block-detail/" + x.block)}>
                                {x.block}
                              </Typography>
                            </a>
                            <FileCopyButton param={x.block}/>
                          </Stack>
                          <Stack direction={"row"} justifyContent={"flex-start"} sx={{ minWidth: 70 }}>
                            <Typography style={{ fontWeight: "500", fontSize: 12 }}>{formatToken(x.height)}</Typography>
                          </Stack>
                          <Stack direction={"row"} justifyContent={"flex-start"} sx={{ minWidth: 160 }}>
                            <Typography style={{ fontWeight: "500", fontSize: 12 }}>{moment(x.timestamp).format("YYYY-MM-DD hh:mm:ss")}</Typography>
                          </Stack>
                          <Stack direction={"row"} justifyContent={"flex-start"} sx={{ minWidth: 150 }}>
                            <Typography style={{ fontWeight: "500", fontSize: 12 }}>{x.numberOfTransactions}</Typography>
                          </Stack>
                          <Stack direction={"row"} gap={1} sx={{ minWidth: 200 }}>
                            <Typography style={{ fontWeight: "500", fontSize: 12, color: blue[500], cursor: 'pointer' }} onClick={() => navigate("/account-detail/" + x.generator)}>{x.generator}</Typography>
                         
                            <FileCopyButton param={x.generator}/>
                          </Stack>
                        </Stack>

                        <Divider sx={{ mt: 1, mb: 1 }} />
                      </Grid>
                    </Grid>
                  </>
                )

                : <Box display="flex" justifyContent="center"
                  alignItems="center"
                  minHeight="50vh">
                  <CircularProgress color="inherit" /> &nbsp;
                  <Typography style={{ fontSize: 12, color: grey[600] }}>Getting data...</Typography>
                </Box>}
            </Box>
            <Stack>
              <table aria-label="custom pagination table">
                <tfoot>
                  <tr>
                    <CustomTablePagination
                      rowsPerPageOptions={[5, 10, 25, 50]}
                      colSpan={3}
                      count={blocks.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      slotProps={{
                        select: {
                          'aria-label': 'Show',
                        },
                        actions: {
                          showFirstButton: true,
                          showLastButton: true,
                        },
                      }}
                      sx={{
                        ".MuiTablePagination-actions .MuiTablePagination-actions": {
                          opacity: 0
                        },
                        ".MuiTablePagination-actions": {
                          opacity: 0
                        },
                        ".MuiTablePagination-displayedRows": {
                          opacity: 0
                        },
                        ".MuiTablePagination-selectLabel": {
                          opacity: 1
                        }
                      }}
                      onPageChange={rowsPerPage >= blocks.length ? handleChangePage : null}
                      onRowsPerPageChange={rowsPerPage <= blocks.length ? handleChangeRowsPerPage : null}
                    />
                    <CustomPagination reachLimit={blocks.length < 50 | blocks.length === 0 ? true : false} handleChangePage={handleChangePage} />
                  </tr>
                </tfoot>
              </table>
            </Stack>
          </Box>
        </Grid>

      </Grid>
    </Box>
  );
}


export default Blocks;