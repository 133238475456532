import React from "react";
import Blocks from '../pages/blocks';
import Page from '../@dwa/shared/page';
import DetailBlock from "../pages/blocks/detail";

const transactionRoutes = [
    {
        path: "/blocks",
        element: <Page component={Blocks} layout={"logged"}/>,
    },
    {
        path: "/block-detail/:blockid",
        element: <Page component={DetailBlock} layout={"logged"}/>,
    }
];

export default transactionRoutes;