import React, { useState, useEffect, useCallback } from "react";
import { Box, Button, Card, CircularProgress, Divider, Grid, Stack, TextField, Typography } from "@mui/material";
import { CardMedia } from '@mui/material';
import { blue, green, grey } from "@mui/material/colors";
import { styled } from '@mui/system';
import axios from 'axios';
import moment from 'moment';
import ForwardIcon from '@mui/icons-material/Forward';
import { useParams, useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import BlockDetailTabTransaction from "../tabs/transaction";
import {FileCopyButton} from '../../../component/copyButton/fileCopyButton'
import TablePagination, {
    tablePaginationClasses as classes,
} from '@mui/base/TablePagination';


const CustomTablePagination = styled(TablePagination)(
    ({ theme }) => `
    & .${classes.spacer} {
      display: none;
    }
  
    & .${classes.toolbar}  {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  
      @media (min-width: 768px) {
        flex-direction: row;
        align-items: center;
      }
    }
  
    & .${classes.selectLabel} {
      margin: 0;
    }
  
    & .${classes.select}{
      padding: 2px 6px;
      border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
      border-radius: 50px;
      background-color: transparent;
      color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  
      &:hover {
        background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
      }
  
      &:focus {
        outline: 1px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
      }
    }
  
    & .${classes.displayedRows} {
      margin: 0;
  
      @media (min-width: 768px) {
        margin-left: auto;
      }
    }
  
    & .${classes.actions} {
      padding: 2px;
      border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
      border-radius: 50px;
      text-align: center;
    }
  
    & .${classes.actions} > button {
      margin: 0 8px;
      border: transparent;
      border-radius: 4px;
      background-color: transparent;
      color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  
      &:hover {
        background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
      }
  
      &:focus {
        outline: 1px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
      }
    }
    `,
);

const DetailBlock = () => {

    const { blockid } = useParams();
    const [page, setPage] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [blockDetail, setBlockDetail] = useState([]);
    const [transAsset, setTransAsset] = useState([]);
    const [isLoaded, setIsloaded] = useState(true);
    const [selectTab, setSelectTab] = useState("");
    const navigate = useNavigate();

    const getBlockDetail = () => {
        return new Promise((resolve, reject) => {
            axios.post(process.env.REACT_APP_BASE_URL + `/api/block/detail/v01`, {
                secretKey: process.env.REACT_APP_SECRET,
                block: blockid,
                height: 1
            })
                .then((response) => {
                    let res = response.data;
                    console.log('block/detail', res)
                    if (Object.keys(res.result.block).length === 0) {
                        navigate(`/not-found/${blockid}`);
                    } else {
                        setBlockDetail(res.result.block);
                    }
                    resolve(res);
                }).catch((err) => {
                    reject(err);
                    console.log(err);
                }).finally(() => {
                    setIsloaded(false);
                });
        });
    }
    const getTransaction = useCallback((inc = 1, rpp) => {
        return new Promise((resolve, reject) => {
            let body = {
                secretKey: process.env.REACT_APP_SECRET,
                rpp: rpp ? rpp : rowsPerPage,
                page_no: inc,
                asset: '',
                account: '',
                block: blockid
            }
            axios.post(process.env.REACT_APP_BASE_URL + `/api/transaction/list/v01`, body)
                .then((response) => {
                    let res = response.data;
                    console.log('getTransaction', res.result.transactionList)

                    if (Object.keys(res.result.transactionList).length === 0) {
                        navigate(`/not-found/${blockid}`);
                    } else {
                        setTransAsset(res.result.transactionList);
                    }
                    resolve(res);
                }).catch((err) => {
                    reject(err);
                    console.log(err);
                }).finally(() => {
                    setIsloaded(false);
                });
        });
    }, [rowsPerPage, page]);

    const handleChangePage = async (props) => {
        const { type, increment } = props;
        console.log('handleChangePage', props)


        if (type !== 'next' && page === 1) {

        }
        else {
            setIsloaded(true);
            setPage(prevState => type === 'next' ? prevState + increment : prevState - increment);
            let state = type === 'next' ? page + 1 : page - 1;
            if (selectTab === '') {
                await getTransaction(state)
            }
        }

    };
    const handleChangeRowsPerPage = async (event) => {
        let rpp = parseInt(event.target.value, 10);
        setRowsPerPage(parseInt(rpp));
        setIsloaded(true);
        let state = page;
        if (selectTab === '') {
            await getTransaction(state,rpp)
        }
    };
    const clearState = () => {
        setPage(1);
        setRowsPerPage(5)
    }
    const initialization = async () => {
        await getBlockDetail();
        await getTransaction();
    }
    const handleSelectTab = (tab) => {
        clearState();
        // if(tab === ""){
        //     navigate(`/token-detail/${asset}`);
        // }else{
        //     navigate(`/token-detail/${asset}/${tab}`);
        // }
    };
    useEffect(() => {
        initialization().catch((err) => console.log(err));
    }, []);

    return (
        <Box>
            <Grid container bgcolor={'white'} spacing={0} sx={{ p: 5 }}>
                <Grid item md={12} xs={12} sx={{ pt: 5 }}>
                    <Stack style={{cursor:'pointer'}} direction={"row"} alignItems={'center'} sx={{ pb: 3 }} onClick={() => navigate("/blocks")}>
                        <ArrowBackIosIcon style={{ fontSize: 14 }} /> Back
                    </Stack>
                    <Box sx={{ border: 0.5, borderColor: 'gray', p: 1, borderRadius: 2 }}>
                        <Grid container>
                            <Grid item md={12}>
                                <Stack direction={"row"} alignItems={'center'}>
                                    <Typography style={{ fontWeight: "800" }}>Block #{blockDetail.block}</Typography>
                                    <FileCopyButton param={blockDetail.block}/>
                                </Stack>
                            </Grid>
                        </Grid>
                        <Divider sx={{ mt: 1, mb: 1 }} />
                        <Grid container>
                            <Grid item md={12} xs={12} sx={{ mt: 1, mb: 1 }}>
                                <Stack direction={"column"} spacing={2}>
                                    <Stack direction={"column"} spacing={{ xs: 4 }}>
                                        <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 1, md: 4 }}>
                                            <Typography style={{ fontSize: 14, minWidth: 150 }}>{"Height:"}</Typography>
                                            <Typography style={{ fontSize: 14 }}>{blockDetail.height}</Typography>
                                        </Stack>
                                        <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 1, md: 4 }}>
                                            <Typography style={{ fontSize: 14, minWidth: 150 }}>{"Timestamp:"}</Typography>
                                            <Typography style={{ fontSize: 14 }}>
                                                <Stack direction={"row"} alignItems={"center"}>
                                                    <AccessTimeIcon style={{ fontSize: 16, color: green[600] }} /> &nbsp; {moment(blockDetail.timestamp).format("DD/MM/YYYY hh:mm:ss")}
                                                </Stack>
                                            </Typography>
                                        </Stack>
                                    </Stack>

                                    {/* <Divider sx={{ mt: 1, mb: 1 }} /> */}

                                    {/* <Stack direction={"column"}>
                                      
                                    </Stack> */}

                                    <Divider sx={{ mt: 1, mb: 1 }} />

                                    <Stack direction={"column"} spacing={{ xs: 4 }} sx={{ mb: 2 }}>
                                        <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 1, md: 4 }} >
                                            <Typography style={{ fontSize: 14, minWidth: 150 }} >{"Total Transaction:"}</Typography>
                                            <Typography style={{ fontSize: 14, backgroundColor: blue[100], padding: 5, borderRadius: 5 }}  >
                                                <Stack direction={"row"} alignItems={"center"} style={{ color: blue[500] }}>
                                                    {blockDetail["transactions"] ? blockDetail["transactions"].length : 0} Transactions
                                                </Stack>
                                            </Typography>
                                        </Stack>
                                        <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 1, md: 4 }} >
                                            <Typography style={{ fontSize: 14, minWidth: 150 }} >{"Total DWA Amount:"}</Typography>
                                            <Typography style={{ fontSize: 14, padding: 5 }}  >{blockDetail.totalAmountNQT}
                                            </Typography>
                                        </Stack>
                                        <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 1, md: 4 }}>
                                            <Typography style={{ fontSize: 14, minWidth: 150 }}>{"Total Fee DWA:"}</Typography>
                                            <Typography style={{ fontSize: 14 }}>{blockDetail.totalFeeNQT}</Typography>
                                        </Stack>
                                    </Stack>
                                    <Divider sx={{ mt: 1, mb: 1 }} />

                                    <Stack direction={"column"} spacing={{ xs: 4 }} sx={{ mb: 2 }}>
                                        <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 1, md: 4 }}>
                                            <Typography style={{ fontSize: 14, minWidth: 150 }}>{"Generator RS:"}</Typography>
                                            <Typography style={{ fontSize: 14 }} alignItems={"center"}>
                                                {blockDetail.generatorRS}
                                                <Button variant="text" style={{ minWidth: 10, height: 20 }} onClick={() => { navigator.clipboard.writeText(blockDetail.generatorRS) }}>
                                                    {/* <FileCopyIcon style={{ color: "#DBBA52", fontSize: 16 }} /> */}
                                                </Button>
                                            </Typography>
                                        </Stack>
                                        <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 1, md: 4 }}>
                                            <Typography style={{ fontSize: 14, minWidth: 150 }}>{"Generator:"}</Typography>
                                            <a style={{ fontSize: 14, color: blue[500], cursor: 'pointer' }} onClick={() => navigate(`/account-detail/${blockDetail.generator}`)}>{blockDetail.generator}
            
                                                <FileCopyButton param={blockDetail.generator}/>
                                            </a>
                                        </Stack>
                                    </Stack>

                                    <Divider sx={{ mt: 1, mb: 1 }} />

                                    {/* <Stack direction={"column"}>
                                        <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 1, md: 4 }}>
                                            <Typography style={{ fontSize: 14, minWidth: 150 }}>{"Generator Public Key:"}</Typography>
                                            <Typography style={{ fontSize: 14 }}>{blockDetail.generatorPublicKey}</Typography>
                                        </Stack>
                                    </Stack>

                                    <Divider sx={{ mt: 1, mb: 1 }} /> */}

                                    <Stack direction={"column"}>
                                        <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 1, md: 4 }}>
                                            <Typography style={{ fontSize: 14, minWidth: 150 }}>{"Payload Hash:"}</Typography>
                                            <Typography style={{ fontSize: 14 }}>{blockDetail.payloadHash}
                                                {/* <Button variant="text" style={{ minWidth: 10, height: 20 }} onClick={() => { navigator.clipboard.writeText(blockDetail.payloadHash) }}>
                                                    <FileCopyIcon style={{ color: "#DBBA52", fontSize: 16 }} />
                                                </Button> */}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid container sx={{ pt: 2 }}>
                    <Grid item md={12} sx={12} xs={12}>
                        <Box sx={{ border: 0.5, borderColor: 'gray', p: 1, borderRadius: 2, width: '100%' }}>
                            <Grid container>
                                <Stack direction={"row"} flex={1} justifyContent={'space-between'}>
                                    <Stack direction={"row"} spacing={{ md: 1, xs: 2 }}>
                                        <Box sx={{ borderBottom: selectTab === "" ? 2 : 0, color: selectTab === "" ? blue[600] : "" }}>
                                            <Typography onClick={() => handleSelectTab("")} style={{ color: selectTab === "" ? blue[600] : 'black', fontWeight: selectTab === 1 ? "800" : '500', paddingRight: 15 }}>{`Transactions`}</Typography>
                                        </Box>
                                        {/* <Box sx={{borderBottom:selectTab === "account" ? 2 : 0,color:selectTab === "account" ? blue[600] : ""}}>
                                            <Typography onClick={()=>handleSelectTab("account")} style={{color:selectTab === "account" ? blue[600] : 'black',fontWeight:selectTab === 2 ? "800" : '500'}}>Reserverd Account</Typography>
                                        </Box> */}
                                    </Stack>
                                    <Stack direction={"row"} spacing={{ md: 3, xs: 2 }}>
                                        <Box sx={{}}>
                                            <Typography>{`Page`} {page}</Typography>
                                        </Box>
                                    </Stack>
                                </Stack>
                            </Grid>
                            <Divider sx={{ mt: 1, mb: 1 }} />
                            <React.Fragment>
                                {selectTab === '' ?
                                    <BlockDetailTabTransaction
                                        transAsset={transAsset}
                                        isLoaded={isLoaded}
                                        page={page}
                                        dataLength={transAsset.length}
                                        rowsPerPage={rowsPerPage}
                                        handleChangePage={handleChangePage}
                                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                                    /> : null}
                            </React.Fragment>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}


export default DetailBlock;