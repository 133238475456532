import React, { useState, useEffect, useCallback } from "react";
import { Box, Button, Card, CircularProgress, Divider, Grid, Stack, TextField, Typography } from "@mui/material";
import { CardMedia } from '@mui/material';
import { blue, grey } from "@mui/material/colors";
import { styled } from '@mui/system';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import AccountDetailTabTransaction from "../tabs/transaction";
import AccountDetailTabToken from "../tabs/token";
import AccountGeneratedBlocks from "../tabs/generatedBlock";
import { useNavigate } from "react-router-dom";
import { FileCopyButton } from "../../../component/copyButton/fileCopyButton";


const AccountDetail = () => {
    const { account, tab } = useParams();
    const [accountInfo, setAccountInfo] = useState([]);
    const [generatedBlocks, setGeneratedBlocks] = useState([]);
    const [isLoaded, setIsloaded] = useState(true);
    const [selectTab, setSelectTab] = useState(tab);
    const [assetTransfers, setAssetTransfers] = useState([]);
    const [assetAccount, setAssetAccount] = useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [page, setPage] = React.useState(1);
    const navigate = useNavigate();
    console.log('account', account)
    const converter = (num, decimal) => {
        // let text = formatRupiah(num);
        return parseInt(num).toFixed(decimal).replace(".", ",");
    }
    const clearState = () => {
        setPage(1);
        setRowsPerPage(5)
    }
    const handleChangeRowsPerPage = async (event) => {
        let rpp = parseInt(event.target.value, 10);
        setRowsPerPage(parseInt(rpp));
        setIsloaded(true);
        let state = page;
        if (selectTab === '') {
            await getTransaction(state, rpp)
        } if (selectTab === 'token') {
            await getAssetAccount(state, rpp)
        } if (selectTab === 'generatedBlocks') {
            await getGeneratedBlocks(state, rpp)
        }
    };
    const handleChangePage = async (props) => {
        const { type, increment } = props;
        console.log('handleChangePage', props)


        if (type !== 'next' && page === 1) {

        }
        else {
            setIsloaded(true);
            setPage(prevState => type === 'next' ? prevState + increment : prevState - increment);
            let state = type === 'next' ? page + 1 : page - 1;
            if (selectTab === '') {
                await getTransaction(state)
            } if (selectTab === 'token') {
                await getAssetAccount(state)
            } if (selectTab === 'generatedBlocks') {
                await getGeneratedBlocks(state)
            }
        }

    };
    const getTransaction = useCallback((inc = 1, rpp) => {
        let body = {
            rpp: rpp ? rpp : rowsPerPage,
            page_no: inc,
            asset: "",
            secretKey: process.env.REACT_APP_SECRET,
            account: account

        }
        return new Promise((resolve, reject) => {
            axios.post(process.env.REACT_APP_BASE_URL + `/api/transaction/list/v01`, body)
                .then((response) => {
                    let res = response.data;
                    console.log('account/transaction/list', res.result)
                    if (typeof (res.result) === 'undefined' || Object.keys(res.result).length === 0) {
                        // navigate(`/not-found/${account}`);
                        setAssetTransfers([])
                    } else {
                        setAssetTransfers(res.result.transactionList);
                    }
                    resolve(res);
                }).catch((err) => {
                    reject(err);
                    console.log(err);
                }).finally(() => {
                    setIsloaded(false);
                });
        });
    }, [rowsPerPage, page]);

    const getAssetAccount = useCallback((inc = 1) => {

        return new Promise((resolve, reject) => {
            axios.post(process.env.REACT_APP_BASE_URL + `/api/account/asset/v01`, {
                secretKey: process.env.REACT_APP_SECRET,
                account: account
            })
                .then((response) => {
                    let res = response.data;
                    console.log('getAssetAccount', getAssetAccount)
                    if (typeof (res.result) === 'undefined' || Object.keys(res.result).length === 0) {
                        setAssetAccount([])
                    } else {
                        setAssetAccount(res.result.accountAssetList);
                    }
                    resolve(res);

                }).catch((err) => {
                    reject(err);
                    console.log(err);
                }).finally(() => {
                    setIsloaded(false);
                });
        });

    }, [rowsPerPage, page]);

    const getAccountInfo = () => {
        console.log('GAF', account)
        return new Promise((resolve, reject) => {
            axios.post(process.env.REACT_APP_BASE_URL + `/api/account/info/v01`, {
                secretKey: process.env.REACT_APP_SECRET,
                account: account
            })
                .then((response) => {
                    let res = response.data;
                    setAccountInfo(res.result.account);
                    resolve(res);
                }).catch((err) => {
                    reject(err);
                    console.log(err);
                }).finally(() => {
                    setIsloaded(false);
                });
        });
    }

    const getGeneratedBlocks = useCallback((inc = 1, rpp) => {
        let temp = rpp ? rpp : rowsPerPage;
        return new Promise((resolve, reject) => {
            console.log('account', account)
            axios.post(process.env.REACT_APP_BASE_URL + `/api/block/list/v01`, {
                secretKey: process.env.REACT_APP_SECRET,
                rpp: temp,
                page_no: inc,
                asset: "",
                account: account
                // account: '16773356556351692521'
            })
                .then((response) => {
                    let res = response.data;
                    console.log('block/list', res)
                    if (typeof (res.result) === 'undefined' || Object.keys(res.result).length === 0) {
                        setGeneratedBlocks([]);
                    } else {
                        setGeneratedBlocks(res.result.blockList);
                    }
                    resolve(res);
                }).catch((err) => {
                    reject(err);
                    console.log(err);
                }).finally(() => {
                    setIsloaded(false);
                });
        });
    }, [rowsPerPage, page]);
    const formatRupiah = (angka = 0, prefix) => {
        var number_string = angka.toString().replace(/[^,\d]/g, ''),
            split = number_string.split(','),
            sisa = split[0].length % 3,
            rupiah = split[0].substr(0, sisa),
            ribuan = split[0].substr(sisa).match(/\d{3}/gi);

        if (ribuan) {
            var separator = sisa ? '.' : '';
            rupiah += separator + ribuan.join('.');
        }
        rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah;
        return prefix === undefined ? rupiah : prefix + ' ' + rupiah;
    };

    const handleSelectTab = (tab) => {
        clearState();
        if (tab === "") {
            navigate(`/account-detail/${account}`);
        } else if (tab === "token") {
            navigate(`/account-detail/${account}/${tab}`);
        } else if (tab === "generatedBlocks") {
            navigate(`/account-detail/${account}/${tab}`);
        }
    };

    const initialization = async () => {
        setSelectTab(tab === undefined ? "" : tab);
        if (tab === undefined) {
            setIsloaded(true);
            await getAccountInfo();
            await getTransaction();
        } else if (tab === 'token') {
            setIsloaded(true);
            await getAccountInfo().then((res) => {
                getAssetAccount();
            });
        } else if (tab === 'generatedBlocks') {
            setIsloaded(true);
            await getGeneratedBlocks().then((res) => {
                // getAssetAccount();
            });
        }
    }

    useEffect(() => {
        initialization().catch((err) => console.log(err));

    }, [tab, account]);

    return (
        <Box>
            <Grid container bgcolor={'white'} spacing={0} sx={{ p: 5, pt: 10 }}>
                <Grid item md={12} xs={12} alignItems={"center"} justifyContent={"center"}>
                    {/* <Stack direction={"row"} alignItems={"center"} sx={{ minWidth:200 }}>
                            <img src={"/images/XDW.png"} style={{height:35,width:35}}/>
                            <Typography sx={{ml:1}} style={{fontWeight:"700",fontSize:16}}>{tokenDetail.name} Token</Typography>
                        </Stack> */}
                </Grid>
                <Grid container spacing={2} sx={{ pt: 2 }}>
                    <Grid item md={12} xs={12}>
                        <Box sx={{ border: 0.5, borderColor: 'gray', p: 1, borderRadius: 2 }}>
                            <Grid container>
                                <Grid item md={12}>Overview</Grid>
                            </Grid>
                            <Divider sx={{ mt: 1, mb: 1 }} />
                            <Grid container spacing={2}>
                                <Grid item md={6} xs={12}>
                                    <Grid item md={12} xs={12} sx={{ pt: 1, pb: 1 }}>
                                        <Typography style={{ color: grey[500] }}>Account ID</Typography>
                                        <Stack direction={"row"} alignItems={"center"} gap={1}>
                                            <Typography >{accountInfo.account}</Typography>
                                            {/* <Button variant="text" style={{ minWidth: 10 }} onClick={() => { navigator.clipboard.writeText(accountInfo.account) }}>
                                                <FileCopyIcon style={{ color: "#DBBA52", fontSize: 16 }} />
                                            </Button> */}
                                            <FileCopyButton param={accountInfo.account} />
                                        </Stack>

                                    </Grid>
                                    <Grid item md={12} xs={12} sx={{ pt: 1, pb: 1 }}>
                                        <Typography style={{ color: grey[500] }}>Name Tag</Typography>
                                        <Typography >{accountInfo.tag}</Typography>
                                    </Grid>
                                    <Grid item md={12} xs={12} sx={{ pt: 1, pb: 1 }}>
                                        <Typography style={{ color: grey[500] }}>URL</Typography>
                                        {
                                            accountInfo.url?
                                            <Stack direction={"row"} alignItems={"center"} gap={1}>
                                                <Typography style={{ fontStyle: 'italic', color: blue[500] }} onClick={() => window.open(accountInfo.url)}>{accountInfo.url}</Typography>
                                                <FileCopyButton param={accountInfo.url} />
                                            </Stack>: null
                                        }

                                    </Grid>
                                </Grid>

                                <Grid item md={6} xs={12}>
                                    <Grid item md={12} xs={12} sx={{ pt: 1, pb: 1 }}>
                                        <Typography style={{ color: grey[500] }}>Account RS</Typography>
                                        <Stack direction={"row"} alignItems={"center"} gap={1}>
                                            <Typography >{accountInfo.accountRS}</Typography>
                                            {/* <Button variant="text"  style={{minWidth:10}} onClick={() => {navigator.clipboard.writeText(accountInfo.accountRS)}}>
                                                <FileCopyIcon style={{color:"#DBBA52",fontSize:16}}/>
                                              </Button> */}
                                        </Stack>

                                    </Grid>
                                    <Grid item md={12} xs={12} sx={{ pt: 1, pb: 1 }}>
                                        <Typography style={{ color: grey[500] }}>Balance NQT</Typography>
                                        <Typography >{accountInfo.balanceNQT}</Typography>
                                    </Grid>
                                    {/* <Grid item md={12} xs={12} sx={{pt:1,pb:1}}>
                                                <Typography style={{color:grey[500]}}>DWA Balance</Typography>
                                                <Typography >{'-'}</Typography>
                                            </Grid> */}
                                </Grid>

                            </Grid>
                        </Box>
                    </Grid>

                </Grid>
                <Grid container sx={{ pt: 2 }}>
                    <Grid item md={12} sx={12} style={{ overflowX: 'auto' }}>
                        <Box sx={{ border: 0.5, borderColor: 'gray', p: 1, borderRadius: 2 }}>
                            <Grid container>
                                <Stack direction={"row"} flex={1} justifyContent={'space-between'}>
                                    <Stack direction={"row"} spacing={{ md: 3, xs: 1 }}>
                                        <Box sx={{ borderBottom: selectTab === "" ? 2 : 0, color: selectTab === "" ? blue[600] : "" }}>
                                            <Typography onClick={() => handleSelectTab("")} style={{ color: selectTab === "" ? blue[600] : 'black', fontWeight: selectTab === 1 ? "800" : '500', cursor: 'pointer' }}>Transactions</Typography>
                                        </Box>
                                        <Box sx={{ borderBottom: selectTab === "token" ? 2 : 0, color: selectTab === "token" ? blue[600] : "" }}>
                                            <Typography onClick={() => handleSelectTab("token")} style={{ color: selectTab === "token" ? blue[600] : 'black', fontWeight: selectTab === 2 ? "800" : '500', cursor: 'pointer' }}>Tokens</Typography>
                                        </Box>
                                        <Box sx={{ borderBottom: selectTab === "generatedBlocks" ? 2 : 0, color: selectTab === "generatedBlocks" ? blue[600] : "" }}>
                                            <Typography onClick={() => handleSelectTab("generatedBlocks")} style={{ color: selectTab === "generatedBlocks" ? blue[600] : 'black', cursor: 'pointer', fontWeight: selectTab === 2 ? "800" : '500' }}>{"Generated Block(s)"}</Typography>
                                        </Box>
                                    </Stack>
                                    <Stack direction={"row"} spacing={{ md: 3, xs: 2 }}>
                                        <Box sx={{}}>
                                            <Typography>{`Page`} {page}</Typography>
                                        </Box>
                                    </Stack>
                                </Stack>
                            </Grid>
                            <Divider sx={{ mt: 1, mb: 1 }} />
                            <React.Fragment>
                                {selectTab === '' ? <AccountDetailTabTransaction dataLength={assetTransfers.length} assetTransfers={assetTransfers} isLoaded={isLoaded} page={page} rowsPerPage={rowsPerPage} handleChangePage={handleChangePage} handleChangeRowsPerPage={handleChangeRowsPerPage} /> : null}
                                {selectTab === 'token' ? <AccountDetailTabToken dataLength={assetAccount.length} assetAccount={assetAccount} isLoaded={isLoaded} /> : null}
                                {selectTab === 'generatedBlocks' ? <AccountGeneratedBlocks dataLength={generatedBlocks.length} generatedBlocks={generatedBlocks} isLoaded={isLoaded} page={page} rowsPerPage={rowsPerPage} handleChangePage={handleChangePage} handleChangeRowsPerPage={handleChangeRowsPerPage} /> : null}
                            </React.Fragment>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}


export default AccountDetail;