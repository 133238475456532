import React, { useState, useEffect } from "react";
import { Box, Button, Card, CircularProgress, Divider, Grid, Stack, TextField, Typography } from "@mui/material";
import { CardMedia } from '@mui/material';
import { blue, grey } from "@mui/material/colors";
import { styled } from '@mui/system';
import axios from 'axios';
import moment from 'moment';
import ForwardIcon from '@mui/icons-material/Forward';
import TablePagination, {
    tablePaginationClasses as classes,
} from '@mui/base/TablePagination';
import { useNavigate } from "react-router-dom";
import { formatTokenDecimals } from "../../helpers/GeneralFunction";
import { FileCopyButton } from "../../component/copyButton/fileCopyButton";
const CustomTablePagination = styled(TablePagination)(
    ({ theme }) => `
    & .${classes.spacer} {
      display: none;
    }
  
    & .${classes.toolbar}  {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  
      @media (min-width: 768px) {
        flex-direction: row;
        align-items: center;
      }
    }
  
    & .${classes.selectLabel} {
      margin: 0;
    }
  
    & .${classes.select}{
      padding: 2px 6px;
      border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
      border-radius: 50px;
      background-color: transparent;
      color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  
      &:hover {
        background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
      }
  
      &:focus {
        outline: 1px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
      }
    }
  
    & .${classes.displayedRows} {
      margin: 0;
  
      @media (min-width: 768px) {
        margin-left: auto;
      }
    }
  
    & .${classes.actions} {
      padding: 2px;
      border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
      border-radius: 50px;
      text-align: center;
    }
  
    & .${classes.actions} > button {
      margin: 0 8px;
      border: transparent;
      border-radius: 4px;
      background-color: transparent;
      color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  
      &:hover {
        background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
      }
  
      &:focus {
        outline: 1px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
      }
    }
    `,
);
const DwaCoinReserve = () => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [tokenList, setTokenList] = useState([]);
    const [coinInfo, setCoinInfo] = useState([]);
    const [isLoaded, setIsloaded] = useState(true);
    const navigate = useNavigate();

    const getCoinInfo = async () => {
        return new Promise((resolve, reject) => {
            axios.post(process.env.REACT_APP_BASE_URL + '/api/coin/info/v01', {
                secretKey: process.env.REACT_APP_SECRET
            })
                .then((response) => {
                    let res = response.data;
                    console.log(res);
                    setCoinInfo(res.result.DWACoinReserve);
                    resolve(res);
                }).catch((err) => {
                    reject(err);
                    console.log(err);
                }).finally(() => {
                    setIsloaded(false);
                });
        });
    }
    const initialization = async () => {
        await getCoinInfo();
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    useEffect(() => {
        initialization().catch((err) => console.log(err));
    }, []);
    return (
        <Box>
            <Grid container bgcolor={'white'} spacing={0} sx={{ p: 5 }}>

                <Grid item md={12} xs={12} sx={{ pt: 10 }}>
                    <Box sx={{ border: 0.5, borderColor: 'gray', p: 1, borderRadius: 2 }}>
                        <Grid container>
                            <Grid item md={12}>DWA Coin Reserve</Grid>
                        </Grid>
                        <Divider sx={{ mt: 1, mb: 1 }} />
                        <Box sx={{ overflowX: 'auto' }}>
                            <Grid container>
                                <Grid item md={12} xs={28} sx={{ mt: 1, mb: 1 }}>
                                    <Stack direction={"row"} spacing={{ xs: 1, md: 4 }}>
                                    <Stack direction={"row"} justifyContent={"flex-start"} sx={{ minWidth: 200 }}>
                                            <Typography style={{ fontWeight: "800", fontSize: 14 }}>Account</Typography>
                                        </Stack>
                                        <Stack direction={"row"} justifyContent={"flex-start"} sx={{ minWidth: 200 }}>
                                            <Typography style={{ fontWeight: "800", fontSize: 14 }}>Tag</Typography>
                                        </Stack>
                                        <Stack direction={"row"} justifyContent={"flex-start"} sx={{ minWidth: 150 }}>
                                            <Typography style={{ fontWeight: "800", fontSize: 14 }}>Total Reserve</Typography>
                                        </Stack>
   
                                    </Stack>
                                    <Divider sx={{ mt: 1, mb: 1 }} />
                                </Grid>
                            </Grid>
                            {!isLoaded && coinInfo.length > 0 ?
                                coinInfo.map((x, i) =>
                                    <>
                                        <Grid container key={i}>
                                            <Grid item md={12} xs={28} sx={{ mt: 1, mb: 1 }}>
                                                <Stack direction={"row"} alignItems={'center'} spacing={{ xs: 1, md: 4 }}>
                                                <Stack direction={"row"} sx={{ minWidth: 200 }}>
                                                        <a href="#" style={{ textDecoration: "none" }}>
                                                            <Typography color={grey[500]} style={{ fontSize: 12, color: blue[500] }} onClick={() => navigate(`/account-detail/${x.account}`)}>
                                                                {x.account}
                                                            </Typography></a>
                                                        <FileCopyButton param={x.account}/>
                                                    </Stack>
                                                    <Stack direction={"row"} sx={{ minWidth: 200 }}>
                                                        <img src={x.imagepath} style={{ height: 30, width: 30 }} />
                                                       <Typography sx={{ ml: 1 }} style={{ fontWeight: "800", fontSize: 14 }}>{x.tag}</Typography>
                                                    </Stack>
                                                    <Stack direction={"column"} alignItems={'flex-end'} sx={{ minWidth: 150 }}>
                                                        <Typography color={grey[500]} style={{ fontSize: 12 }}>{x.total_reserve}</Typography>
                                                    </Stack>
                                                  
                                                </Stack>
                                                <Divider sx={{ mt: 1, mb: 1 }} />
                                            </Grid>
                                        </Grid>

                                    </>

                                )

                                : <Box display="flex" justifyContent="center"
                                    alignItems="center"
                                    minHeight="50vh">
                                    <CircularProgress color="inherit" /> &nbsp;
                                    <Typography style={{ fontSize: 12, color: grey[600] }}>Getting data...</Typography>
                                </Box>}
                        </Box>
                        <Stack>
                            {/* <table aria-label="custom pagination table">
                                <tfoot>
                                    <tr>
                                        <CustomTablePagination
                                            rowsPerPageOptions={[5, 10, 25, { label: '100', value: 100 }]}
                                            colSpan={3}
                                            count={coinInfo.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            slotProps={{
                                                select: {
                                                    'aria-label': 'Show',
                                                },
                                                actions: {
                                                    showFirstButton: true,
                                                    showLastButton: true,
                                                },
                                            }}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        />
                                    </tr>
                                </tfoot>
                            </table> */}
                        </Stack>
                    </Box>
                </Grid>

            </Grid>
        </Box>
    );
}
export default DwaCoinReserve;