import React from "react";
import Grid from '@mui/material/Grid';
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Dashboard = () =>{
    

    return (
        <Box>
            <Grid container sx={{pt:2}} justify="center">
                <Grid item md={6} xs={12} align={"center"} sx={{pt:20}}>
                    <img src={'/images/DWA_Blockchain.png'} style={{width:500,height:160}}/>
                    <Typography style={{color:'white'}} sx={{pl:15,pr:15}}>
                        a 100% proof-of-stake blockchain designed to expand the use of blockchain technology beyond the simple transfer of value
                    </Typography>
                </Grid>
                <Grid item md={6} xs={12} align="center" >
                    <img src={'/images/Hero.png'} style={{width:600,height:650}}/>
                </Grid>
            </Grid>
            <Grid container justify="center" sx={{pb:5}}>
                <Grid item md={6} xs={12} align={"center"}>
                    <img src={'/images/DWA_flash.png'} style={{width:550,height:500}}/>
                </Grid>
                <Grid item md={6} xs={12} align="center" >
                    <Typography variant="body1" style={{color:'white'}} sx={{pl:12,pr:12,pt:20}}>
                        DWA is a 100% proof-of-stake cryptocurrency which designed to expand the use of blockchain technology beyond the simple transfer of value. Its technology is adopted from NXT blockchain. DWA is created to be an useful payment technology that gives users the benefits of cryptocurrency but more stable in terms of price.
                    </Typography>
                </Grid>
            </Grid>
            <Grid container justify="center" sx={{pb:5}}>
                <Grid item md={12} xs={12} align={"center"}>
                    <img src={'/images/discover_ourtoken.png'} style={{width:'auto',height:700}}/>
                </Grid>
            </Grid>
            <Grid container justify="center" sx={{pb:5}}>
                <Grid item md={12} xs={12} align={"center"}>
                    <Typography variant={"body1"} style={{fontSize:40,color:'#FFEC7F'}}>Contribute to DWA</Typography>
                    <Typography variant={"body1"} color={'white'} style={{fontSize:12}}>DWA is all about community. It's made up of people from all different
                        backgrounds and interests. See how you can join in.</Typography>
                </Grid>
            </Grid>
        </Box>
    );
}

export default Dashboard;