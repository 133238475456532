import React, { useState, useEffect } from "react";
import { Box, Button, Card, CircularProgress, Divider, Grid, Stack, TextField, Tooltip, Typography } from "@mui/material";
import { CardMedia } from '@mui/material';
import { blue, green, grey } from "@mui/material/colors";
import { styled } from '@mui/system';
import axios from 'axios';
import moment from 'moment';
import ForwardIcon from '@mui/icons-material/Forward';
import { useParams, useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import HelpOutline from '@mui/icons-material/HelpOutline';
import TablePagination, {
    tablePaginationClasses as classes,
} from '@mui/base/TablePagination';

import Container from '@mui/material/Container';
const CustomTablePagination = styled(TablePagination)(
    ({ theme }) => `
    & .${classes.spacer} {
      display: none;
    }
  
    & .${classes.toolbar}  {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  
      @media (min-width: 768px) {
        flex-direction: row;
        align-items: center;
      }
    }
  
    & .${classes.selectLabel} {
      margin: 0;
    }
  
    & .${classes.select}{
      padding: 2px 6px;
      border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
      border-radius: 50px;
      background-color: transparent;
      color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  
      &:hover {
        background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
      }
  
      &:focus {
        outline: 1px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
      }
    }
  
    & .${classes.displayedRows} {
      margin: 0;
  
      @media (min-width: 768px) {
        margin-left: auto;
      }
    }
  
    & .${classes.actions} {
      padding: 2px;
      border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
      border-radius: 50px;
      text-align: center;
    }
  
    & .${classes.actions} > button {
      margin: 0 8px;
      border: transparent;
      border-radius: 4px;
      background-color: transparent;
      color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  
      &:hover {
        background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
      }
  
      &:focus {
        outline: 1px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
      }
    }
    `,
);

const DetailTransaction = () => {

    const { transactionid } = useParams();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [transactionDetail, setTransactionDetail] = useState([]);
    const [isLoaded, setIsloaded] = useState(true);
    const navigate = useNavigate();

    const getTransactions = () => {
        return new Promise((resolve, reject) => {
            axios.post(process.env.REACT_APP_BASE_URL + `/api/transaction/detail/v01`, {
                secretKey: process.env.REACT_APP_SECRET,
                transaction: transactionid
            })
                .then((response) => {
                    let res = response.data;
                    console.log('transaction/detail', res)
                    if (Object.keys(res.result.transaction).length === 0) {
                        navigate(`/not-found/${transactionid}`);
                    } else {
                        setTransactionDetail(res.result.transaction);
                    }
                    resolve(res);
                }).catch((err) => {
                    reject(err);
                    console.log(err);
                }).finally(() => {
                    setIsloaded(false);
                });
        });
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const initialization = async () => {
        await getTransactions();
    }

    useEffect(() => {
        initialization().catch((err) => console.log(err));
    }, []);

    return (
        <Box>
            <Grid container bgcolor={'white'} spacing={0} sx={{ p: 5 }}>
                <Grid item md={12} xs={100} sx={{ pt: 5, overflowX: 'auto' }}>
                    <Stack direction={"row"} style={{ cursor: 'pointer' }} alignItems={'center'} sx={{ pb: 3 }} onClick={() => navigate("/transaction")}>
                        <ArrowBackIosIcon style={{ fontSize: 14 }} /> Back
                    </Stack>
                    <Box sx={{ border: 0.5, borderColor: 'gray', p: 1, borderRadius: 2 }}>
                        <Grid container>
                            <Grid item md={12}><Typography style={{ fontWeight: "800" }}>Transaction Detail</Typography></Grid>
                        </Grid>
                        <Stack>
                            <Divider sx={{ mt: 1, mb: 1, maxWidth: '100%' }} /> {/* Set the maximum width to 100% */}
                        </Stack>
                        <Box sx={{ padding: 0, overflowX: 'auto' }}>
                            <Grid container>
                                <Grid item md={12} xs={100} sx={{ mt: 1, mb: 1 }}>
                                    <Stack direction={"column"} spacing={2}>
                                        <Stack direction={"column"}>
                                            <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 1, md: 4 }}>
                                                <Typography style={{ fontSize: 14, minWidth: 150 }}>{"Transaction:"}</Typography>
                                                <Stack direction={"row"}>                                    <Typography style={{ fontSize: 14 }}>{transactionDetail.transaction}</Typography>
                                                    <Button variant="text" style={{ minWidth: 10, height: 20 }} onClick={() => { navigator.clipboard.writeText(transactionDetail.transaction) }}>
                                                        <FileCopyIcon style={{ color: "#DBBA52", fontSize: 16 }} />
                                                    </Button>
                                                </Stack>

                                            </Stack>
                                        </Stack>

                                        <Stack direction={"column"}>
                                            <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 1, md: 4 }}>
                                                <Typography style={{ fontSize: 14, minWidth: 150 }}>{"Status:"}</Typography>
                                                <Typography style={{ fontSize: 14, backgroundColor: grey[300], padding: 5, borderRadius: 5 }} alignItems={"center"}>
                                                    <Stack direction={"row"} alignItems={"center"} >
                                                        <CheckCircleOutlineIcon style={{ color: green[600], fontSize: 16 }} /> &nbsp; {transactionDetail.status}
                                                    </Stack>
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                        <Stack direction={"column"}>
                                            <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 1, md: 4 }}>
                                                <Typography style={{ fontSize: 14, minWidth: 150 }}>{"Confirmations:"}</Typography>
                                                <Typography style={{ fontSize: 14 }}>{transactionDetail.confirmations}</Typography>
                                            </Stack>
                                        </Stack>

                                        <Stack direction={"column"}>
                                            <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 1, md: 4 }}>
                                                <Typography style={{ fontSize: 14, minWidth: 150 }}>{"Timestamp:"}</Typography>
                                                <Typography style={{ fontSize: 14 }}>
                                                    <Stack direction={"row"} alignItems={"center"}>
                                                        <AccessTimeIcon style={{ fontSize: 16, color: green[600] }} /> &nbsp; {moment(transactionDetail.timestamp).format("DD/MM/YYYY HH:mm:ss")}
                                                    </Stack>
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                        <Stack>
                                            <Divider sx={{ mt: 1, mb: 1, maxWidth: '100%' }} /> {/* Set the maximum width to 100% */}
                                        </Stack>
                                        <Stack direction={"column"}>
                                            <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 1, md: 4 }}>
                                                <Typography style={{ fontSize: 14, minWidth: 150 }}>{"Token:"}</Typography>
                                                {
                                                    transactionDetail?.attachment?.asset ?
                                                        <Stack direction={{ xs: "row", md: "row" }} spacing={{ xs: 1, md: 4 }}>
                                                            <img src={transactionDetail.attachment.asset_imagepath} style={{ width: 20, height: 20 }}></img>
                                                            <Typography style={{ fontSize: 14, minWidth: 150 }}>{transactionDetail.attachment.asset_name}</Typography>
                                                        </Stack>
                                                        : null
                                                }

                                            </Stack>
                                        </Stack>
                                        <Stack direction={"column"}>
                                            <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 1, md: 4 }}>
                                                <Typography style={{ fontSize: 14, minWidth: 150 }}>{"Token Amount:"}</Typography>
                                                {
                                                    transactionDetail.attachment?.asset ?
                                                        <Typography style={{ fontSize: 14 }}>{transactionDetail.attachment.quantityQNT}</Typography> : null
                                                }

                                            </Stack>
                                        </Stack>
                                        <Stack direction={"column"}>
                                            <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 1, md: 4 }}>
                                                <Typography style={{ fontSize: 14, minWidth: 150 }}>{"Fee Token:"}</Typography>
                                                {
                                                    transactionDetail.attachment?.asset ?
                                                        <Typography style={{ fontSize: 14 }}>{transactionDetail.attachment.feeQNT}</Typography> : null
                                                }

                                            </Stack>
                                        </Stack>
                                        <Stack direction={"column"}>
                                            <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 1, md: 4 }}>
                                                <Typography style={{ fontSize: 14, minWidth: 150 }}>{"DWA Amount:"}</Typography>
                                                <Typography style={{ fontSize: 14 }}>{transactionDetail.amountNQT}</Typography>
                                            </Stack>
                                        </Stack>
                                        <Stack direction={"column"}>
                                            <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 1, md: 4 }}>
                                                <Typography style={{ fontSize: 14, minWidth: 150 }}>{"Fee DWA:"}</Typography>
                                                <Typography style={{ fontSize: 14 }}>{transactionDetail.feeNQT}</Typography>
                                            </Stack>
                                        </Stack>
                                        <Stack>
                                            <Divider sx={{ mt: 1, mb: 1, maxWidth: '100%' }} /> {/* Set the maximum width to 100% */}
                                        </Stack>
                                        <Stack direction={"column"}>
                                            <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 1, md: 4 }}>
                                                <Typography style={{ fontSize: 14, minWidth: 150 }}>{"recipient:"}</Typography>
                                                <Stack direction={"row"}>
                                                    <a href="#" style={{ textDecoration: "none" }}><Typography style={{ fontSize: 14, color: blue[500] }} onClick={() => navigate("/account-detail/" + transactionDetail.recipient)}>{transactionDetail.recipient}</Typography></a>
                                                    <Button variant="text" style={{ minWidth: 10, height: 20 }} onClick={() => { navigator.clipboard.writeText(transactionDetail.recipient) }}>
                                                        <FileCopyIcon style={{ color: "#DBBA52", fontSize: 16 }} />
                                                    </Button>
                                                </Stack>

                                            </Stack>
                                        </Stack>

                                        <Stack direction={"column"}>
                                            <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 1, md: 4 }}>
                                                <Typography style={{ fontSize: 14, minWidth: 150 }}>{"recipientRS:"}</Typography>

                                                <Stack direction={"row"}>
                                                    <Typography style={{ fontSize: 14 }}>{transactionDetail.recipientRS}</Typography>
                                                </Stack>
                                            </Stack>
                                        </Stack>
                                        <Stack direction={"column"}>
                                            <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 1, md: 4 }}>
                                                    <Typography style={{ fontSize: 14, minWidth: 150 }}> {"Tag:"}</Typography>
                                                    {
                                                        transactionDetail.recipient_tag ?
                                                            <Stack direction={"row"}>

                                                                <img src={transactionDetail.recipient_imagepath} style={{ width: 20, height: 20 }}></img>
                                                                <Typography style={{ fontSize: 14, minWidth: 150 }}>{transactionDetail.recipient_tag}</Typography>
                                                            </Stack> : null
                                                    }



                                            </Stack>
                                        </Stack>
                                        <Stack>
                                            <Divider sx={{ mt: 1, mb: 1, maxWidth: '100%' }} /> {/* Set the maximum width to 100% */}
                                        </Stack>
                                        <Stack direction={"column"}>
                                            <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 1, md: 4 }}>
                                                <Typography style={{ fontSize: 14, minWidth: 150 }}>{"sender:"}</Typography>
                                                <Stack direction={"row"}>
                                                    <a href="#" style={{ textDecoration: "none" }}><Typography style={{ fontSize: 14, color: blue[500] }} onClick={() => navigate("/account-detail/" + transactionDetail.sender)}>{transactionDetail.sender}</Typography></a>
                                                    <Button variant="text" style={{ minWidth: 10, height: 20 }} onClick={() => { navigator.clipboard.writeText(transactionDetail.sender) }}>
                                                        <FileCopyIcon style={{ color: "#DBBA52", fontSize: 16 }} />
                                                    </Button>
                                                </Stack>
                                            </Stack>
                                        </Stack>

                                        {/* <Stack direction={"column"}>
                                                <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 1, md: 4 }}>
                                                    <Typography style={{ fontSize: 14, minWidth: 150 }}>{"senderPublicKey:"}</Typography>
                                                    <Stack direction={"row"}>
                                                        <a href="#" style={{ textDecoration: "none" }}><Typography style={{ fontSize: 14, color: blue[500] }} onClick={() => navigate("/account-detail/" + transactionDetail.sender)}>{transactionDetail.sender}</Typography></a>
                                                        <Button variant="text" style={{ minWidth: 10, height: 20 }} onClick={() => { navigator.clipboard.writeText(transactionDetail.sender) }}>
                                                            <FileCopyIcon style={{ color: "#DBBA52", fontSize: 16 }} />
                                                        </Button>
                                                    </Stack>
                                                </Stack>
                                            </Stack> */}

                                        <Stack direction={"column"}>
                                            <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 1, md: 4 }}>
                                                <Typography style={{ fontSize: 14, minWidth: 150 }}>{"senderRS:"}</Typography>
                                                <Stack direction={"row"}>
                                                    <Typography style={{ fontSize: 14 }}>{transactionDetail.senderRS}</Typography>
                                                    {/* <Button variant="text" style={{ minWidth: 10, height: 20 }} onClick={() => { navigator.clipboard.writeText(transactionDetail.senderRS) }}>
                                                        <FileCopyIcon style={{ color: "#DBBA52", fontSize: 16 }} />
                                                    </Button> */}
                                                </Stack>

                                            </Stack>
                                        </Stack>
                                        <Stack direction={"column"}>
                                            <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 1, md: 4 }}>
                                                <Typography style={{ fontSize: 14, minWidth: 150 }}>{"Tag:"}</Typography>
                                                {
                                                    transactionDetail.sender_tag ?
                                                        <Stack direction={{ xs: "row", md: "row" }} spacing={{ xs: 1, md: 4 }}>
                                                            <img src={transactionDetail.sender_imagepath} style={{ width: 20, height: 20 }}></img>
                                                            <Typography style={{ fontSize: 14, minWidth: 150 }}>{transactionDetail.sender_tag}</Typography>

                                                        </Stack> : null
                                                }


                                            </Stack>
                                        </Stack>


                                        <Stack>
                                            <Divider sx={{ mt: 1, mb: 1, maxWidth: '100%' }} /> {/* Set the maximum width to 100% */}
                                        </Stack>
                                        <Stack direction={"column"}>
                                            <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 1, md: 4 }}>
                                                <Typography style={{ fontSize: 14, minWidth: 150 }}>{"Block:"}</Typography>
                                                <Stack direction={"row"}>
                                                    <a href="#" style={{ textDecoration: "none" }}><Typography style={{ fontSize: 14, color: blue[500] }} onClick={() => navigate(`/block-detail/${transactionDetail.block}`)}>{transactionDetail.block}</Typography></a>

                                                    <Button variant="text" style={{ minWidth: 10, height: 20 }} onClick={() => { navigator.clipboard.writeText(transactionDetail.block) }}>
                                                        <FileCopyIcon style={{ color: "#DBBA52", fontSize: 16 }} />
                                                    </Button>
                                                </Stack>
                                            </Stack>
                                        </Stack>
                                        {/* <Stack direction={"column"}>
                                                <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 1, md: 4 }}>
                                                    <Typography style={{ fontSize: 14, minWidth: 150 }}>{"blockTimestamp:"}</Typography>
                                                    <Typography style={{ fontSize: 14 }}>{transactionDetail.blockTimestamp}</Typography>
                                                </Stack>
                                            </Stack> */}

                                        <Stack direction={"column"}>
                                            <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 1, md: 4 }}>
                                                <Typography style={{ fontSize: 14, minWidth: 150 }}>{"Deadline:"}</Typography>
                                                <Typography style={{ fontSize: 14 }}>{transactionDetail.deadline}</Typography>
                                            </Stack>
                                        </Stack>

                                        <Stack direction={"column"}>
                                            <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 1, md: 4 }}>
                                                <Typography style={{ fontSize: 14, minWidth: 150 }}>{"ecBlockHeight:"}</Typography>
                                                <Typography style={{ fontSize: 14 }}>{transactionDetail.ecBlockHeight}</Typography>
                                            </Stack>
                                        </Stack>

                                        <Stack direction={"column"}>
                                            <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 1, md: 4 }}>
                                                <Typography style={{ fontSize: 14, minWidth: 150 }}>{"Height:"}</Typography>
                                                <Typography style={{ fontSize: 14 }}>{transactionDetail.height}</Typography>
                                            </Stack>
                                        </Stack>

                                        <Stack direction={"column"}>
                                            <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 1, md: 4 }}>
                                                <Typography style={{ fontSize: 14, minWidth: 150 }}>{"fullHash:"}</Typography>
                                                <Stack direction={"row"}>
                                                    <Typography style={{ fontSize: 14 }}>{transactionDetail.fullHash}</Typography>
                                                    {/* <Button variant="text" style={{ minWidth: 10, height:20 }} onClick={() => { navigator.clipboard.writeText(transactionDetail.fullHash) }}>
                                                <FileCopyIcon style={{ color: "#DBBA52", fontSize: 16 }} />
                                            </Button> */}
                                                </Stack>

                                            </Stack>
                                        </Stack>
                                        <Stack>
                                            <Divider sx={{ mt: 1, mb: 1, maxWidth: '100%' }} /> {/* Set the maximum width to 100% */}
                                        </Stack>

                                        <Stack direction={"column"}>
                                            <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 1, md: 4 }}>
                                                <Typography style={{ fontSize: 14, minWidth: 150 }}>{"signature:"}</Typography>
                                                <Typography style={{ fontSize: 14 }}>{transactionDetail.signature}</Typography>

                                            </Stack>
                                        </Stack>

                                        {/* <Stack direction={"column"}>
                                            <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 1, md: 4 }}>
                                                <Typography style={{ fontSize: 14, minWidth: 150 }}>{"transaction:"}</Typography>

                                                <Stack direction={"row"}>
                                                    <Typography style={{ fontSize: 14 }}>{transactionDetail.transaction}</Typography>
                                                    <Button variant="text" style={{ minWidth: 10, height: 20 }} onClick={() => { navigator.clipboard.writeText(transactionDetail.transaction) }}>
                                                        <FileCopyIcon style={{ color: "#DBBA52", fontSize: 16 }} />
                                                    </Button>
                                                </Stack>

                                            </Stack>
                                        </Stack> */}

                                        {/* <Stack direction={"column"}>
                                                <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 1, md: 4 }}>
                                                    <Typography style={{ fontSize: 14, minWidth: 150 }}>{"transactionIndex:"}</Typography>
                                                    <Typography style={{ fontSize: 14 }}>{transactionDetail.transactionIndex}</Typography>
                                                </Stack>
                                            </Stack> */}

                                        <Stack direction={"column"}>
                                            <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 1, md: 4 }}>
                                                <Typography style={{ fontSize: 14, minWidth: 150 }}>{"SignatureHash:"}</Typography>
                                                <Typography style={{ fontSize: 14 }}>{transactionDetail.signatureHash}</Typography>
                                            </Stack>
                                        </Stack>

                                        {/* <Stack direction={"column"}>
                                                <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 1, md: 4 }}>
                                                    <Typography style={{ fontSize: 14, minWidth: 150 }}>{"subType:"}</Typography>
                                                    <Typography style={{ fontSize: 14 }}>{transactionDetail.subtype}</Typography>
                                                </Stack>
                                            </Stack> */}

                                    </Stack>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}


export default DetailTransaction;