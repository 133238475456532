import React, { useCallback, useState } from "react";
import Grid from '@mui/material/Grid';
import { Box, Button, Divider, Stack, Tab, Tabs, TextField, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import SendIcon from '@mui/icons-material/Send';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { BrowserView, MobileView } from 'react-device-detect';
import TemporaryDrawer from "../drawer";
import axios from "axios";
import SearchIcon from '@mui/icons-material/Search';
import SubdirectoryArrowLeftIcon from '@mui/icons-material/SubdirectoryArrowLeft';
const HeaderTransaction = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [search, setSearch] = useState();

    const onSearch = useCallback(() => {
        return new Promise((resolve, reject) => {
            axios.post(process.env.REACT_APP_BASE_URL + '/api/globalsearch/v01', {
                secretKey: process.env.REACT_APP_SECRET,
                keyword: search
            })
                .then((response) => {
                    let res = response.data;
                    console.log("Search Global Result: ", res.result);
                    if (res.status) {

                        if (res.result.account === undefined) {
                            if (res.result.transaction !== undefined) {
                                navigate("/transaction-detail/" + res.result.transaction.transaction);
                            }

                            if (res.result.block !== undefined) {
                                navigate("/block-detail/" + res.result.block.block);
                            }
                        } else {
                            if (res.result.account.account === "") {
                                navigate(`/not-found/${search}`);
                            } else {
                                navigate("/account-detail/" + res.result.account.account);
                            }
                        }


                        // if(res.result.account !== undefined){
                        //     navigate("/account-detail/"+res.result.account.account);
                        // }
                    } else {
                        alert(res.message);
                    }


                    // if(res.result)
                    resolve(res);
                }).catch((err) => {
                    reject(err);
                    console.log(err);
                });
        });
    }, [search]);

    const switcher = () => {
        let testnet = 'https://testnet.explorer.dwa.network';
        let livenet = 'https://explorer.dwa.network';
        let devnet = "http://localhost:3000";
        if (testnet.match(window.location.origin)) {
            return {
                title: "DWA Live Net",
                url: livenet
            };
        } else if (livenet.match(window.location.origin)) {
            return {
                title: "DWA Test Net",
                url: testnet
            };
        } else {
            return {
                title: "DWA Dev Net",
                url: devnet
            };
        }


    }

    return (
        <Box width="100%" bgcolor={'#1F2A37'} style={{ position: 'fixed', zIndex: 99 }} >
            <MobileView>
                {
                    location.pathname.substring(1) !== "home" ?
                        <>
                           <Box display="flex" direction="row" alignItems="center"
                    justifyContent="center" height={60} marginY={1}>

                    <Grid item alignSelf={'center'} >
                        <Stack direction={"row"} alignItems={"center"} >

                            <Stack style={{zIndex: 2, position: 'absolute', left: '6vw', }}>
                                <SearchIcon style={{ color: "#6B7280", fontSize: 20, marginInline: 4 }} />
                            </Stack>
                            <a href="#" style={{ justifyContent: 'center', width: 40, zIndex: 2, position: 'absolute', right: '6vw', display:'flex' ,justifyContent:'center', paddingBlock:8, paddingInline:10, borderRadius:4}}
                                onClick={() => { onSearch() }}>
                                <SubdirectoryArrowLeftIcon style={{ color: "#DBBA52", fontSize: 18 }} />
                            </a>

                            <TextField
                                onKeyDown={(ev) => {
                                    if (ev.key === 'Enter') {
                                        onSearch();
                                        ev.preventDefault();
                                    }
                                }}
                                inputProps={{ style: { fontSize: 14, justifyContent: 'center', paddingInline: 45, backgroundColor: '#F9FAFB', borderRadius: 8 } }}
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                InputLabelProps={{ style: { fontSize: 12 }, shrink: true }}
                                id="outlined-basic"
                                placeholder="Search by Block / Transaction Id / Address"
                                variant="outlined"
                                size="small"
                                style={{
                                    width: '90vw', paddingBlock: 20, textDecoration: 'none'

                                }}
                            />
                            &nbsp;&nbsp;
                        </Stack>

                    </Grid>
                </Box>
                            <Divider sx={{ background: 'gray' }} />
                        </> : null
                }
            </MobileView>
            <BrowserView>
                <Box display="flex" direction="row" alignItems="center"
                    justifyContent="center" height={60} marginY={1}>

                    <Grid item alignSelf={'center'} >
                        <Stack direction={"row"} alignItems={"center"} >

                            <Stack style={{zIndex: 2, position: 'absolute', left: '25.5vw', }}>
                                <SearchIcon style={{ color: "#6B7280", fontSize: 20, marginInline: 4 }} />
                            </Stack>
                            <a href="#" style={{ justifyContent: 'center', width: 40, zIndex: 2, position: 'absolute', right: '25.5vw', display:'flex' ,justifyContent:'center', paddingBlock:8, paddingInline:10, borderRadius:4}}
                                onClick={() => { onSearch() }}>
                                <SubdirectoryArrowLeftIcon style={{ color: "#DBBA52", fontSize: 18 }} />
                            </a>

                            <TextField
                                onKeyDown={(ev) => {
                                    if (ev.key === 'Enter') {
                                        onSearch();
                                        ev.preventDefault();
                                    }
                                }}
                                inputProps={{ style: { fontSize: 14, justifyContent: 'center', paddingInline: 55, backgroundColor: '#F9FAFB', borderRadius: 8 } }}
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                InputLabelProps={{ style: { fontSize: 12 }, shrink: true }}
                                id="outlined-basic"
                                placeholder="Search by Block / Transaction Id / Address"
                                variant="outlined"
                                size="small"
                                style={{
                                    width: '50vw', paddingBlock: 20, textDecoration: 'none'

                                }}
                            />
                            &nbsp;&nbsp;
                        </Stack>

                    </Grid>
                </Box>
                <Divider sx={{ background: 'gray' }} />
            </BrowserView>



            <MobileView>
                <Grid container sx={{ paddingY: 1 }}>
                    <Grid item md={6} xs={6} sx={{ pl: 5 }}>
                        <a href="#" style={{ textDecoration: "none" }} onClick={() => navigate("/home")} onMouseEnter={() => null}>
                            <Stack direction={"row"} alignItems="center">
                                <img src={'/images/Logo.png'} style={{ width: 70, height: 25 }} />
                                {/* <Typography style={{color:'white',marginLeft:10,fontSize:12,fontWeight:'bold'}}>DWA Explorer</Typography> */}
                            </Stack>
                            <Typography style={{ backgroundColor: 'transparent', padding: 0, color: '#DBBA52', fontSize: 10 }} disabled>{process.env.REACT_APP_NET}</Typography>
                        </a>
                    </Grid>
                    <Grid item md={6} xs={6} align={"right"} sx={{ pr: 3, pt: 1 }}>
                        <TemporaryDrawer />
                    </Grid>
                </Grid>
            </MobileView>

            <BrowserView>
                <Box display="flex" alignItems="center"
                    justifyContent="center" height={65}>
                    <Grid container>
                        <Grid item md={6} xs={6} sx={{ pl: 5 }}>
                            <a href="#" style={{ textDecoration: "none" }} onClick={() => navigate("/home")} onMouseEnter={() => null}>
                                <Stack direction={"row"} alignItems="center">
                                    <img src={'/images/icon1.png'} style={{ width: 35, height: 35 }} />
                                    <Typography style={{ color: 'white', marginLeft: 10, fontSize: 20, fontWeight: 'bold' }}>DWA Explorer</Typography>
                                </Stack>
                                <Typography style={{ backgroundColor: 'transparent', padding: 0, color: '#DBBA52', fontSize: 10 }} disabled>{process.env.REACT_APP_NET}</Typography>
                            </a>


                        </Grid>
                        <Grid item md={6} xs={6} align={"right"} sx={{ pr: 10, pt: 1 }}>
                            <Stack direction="row" spacing={2} justifyContent="flex-end" alignItems={"center"}>
                                <a href="#" style={{ textDecoration: "none" }}><Typography style={{ color: location.pathname.substring(1) === "home" ? "#D8B64A" : 'white', fontWeight: location.pathname.substring(1) === "home" ? "bold" : "lighter", fontSize: 14 }} onClick={() => navigate("/home")} onMouseEnter={() => null}>Home</Typography></a>
                                <a href="#" style={{ textDecoration: "none" }}><Typography style={{ color: location.pathname.substring(1) === "blocks" ? "#D8B64A" : 'white', fontWeight: location.pathname.substring(1) === "blocks" ? "bold" : "lighter", fontSize: 14 }} onClick={() => navigate("/blocks")}>Blocks</Typography></a>
                                <a href="#" style={{ textDecoration: "none" }}><Typography style={{ color: location.pathname.substring(1) === "transaction" ? "#D8B64A" : 'white', fontWeight: location.pathname.substring(1) === "transaction" ? "bold" : "lighter", fontSize: 14 }} onClick={() => navigate("/transaction")}>Transactions</Typography></a>
                                <a href="#" style={{ textDecoration: "none" }}><Typography style={{ color: location.pathname.substring(1) === "token" ? "#D8B64A" : 'white', fontWeight: location.pathname.substring(1) === "token" ? "bold" : "lighter", fontSize: 14 }} onClick={() => navigate("/token")}>Tokens</Typography></a>
                                <a href="#" style={{ textDecoration: "none" }}><Typography style={{ color: location.pathname.substring(1) === "dwa-coin-reserve" ? "#D8B64A" : 'white', fontWeight: location.pathname.substring(1) === "dwa-coin-reserve" ? "bold" : "lighter", fontSize: 14 }} onClick={() => navigate("/dwa-coin-reserve")}>DWA Reserve Account</Typography></a>
                                {/* <Typography style={{color:'white',fontSize:14}}>Account</Typography> */}
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
            </BrowserView>

        </Box>
    );
}

export default HeaderTransaction;