import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Box, Button, Card, CircularProgress, Divider, Grid, Stack, TextField, Typography } from "@mui/material";
import { CardMedia } from '@mui/material';
import { blue, grey } from "@mui/material/colors";
import { styled } from '@mui/system';
import axios from 'axios';
import moment from 'moment';
import ForwardIcon from '@mui/icons-material/Forward';
import { useParams } from 'react-router-dom';
import TablePagination, {
    tablePaginationClasses as classes,
} from '@mui/base/TablePagination';
import TokenDetailTabTransaction from "../tabs/transaction";
import TokenDetailTabAccount from "../tabs/account";
import { useNavigate } from "react-router-dom";
import { formatTokenDecimals } from "../../../helpers/GeneralFunction";
import FileCopyIcon from '@mui/icons-material/FileCopy';
import TokenDetailTabAccount1 from "../tabs/account1";


const CustomTablePagination = styled(TablePagination)(
    ({ theme }) => `
    & .${classes.spacer} {
      display: none;
    }
  
    & .${classes.toolbar}  {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  
      @media (min-width: 768px) {
        flex-direction: row;
        align-items: center;
      }
    }
  
    & .${classes.selectLabel} {
      margin: 0;
    }
  
    & .${classes.select}{
      padding: 2px 6px;
      border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
      border-radius: 50px;
      background-color: transparent;
      color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  
      &:hover {
        background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
      }
  
      &:focus {
        outline: 1px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
      }
    }
  
    & .${classes.displayedRows} {
      margin: 0;
  
      @media (min-width: 768px) {
        margin-left: auto;
      }
    }
  
    & .${classes.actions} {
      padding: 2px;
      border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
      border-radius: 50px;
      text-align: center;
    }
  
    & .${classes.actions} > button {
      margin: 0 8px;
      border: transparent;
      border-radius: 4px;
      background-color: transparent;
      color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  
      &:hover {
        background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
      }
  
      &:focus {
        outline: 1px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
      }
    }
    `,
);

const TokenDetail = () => {
    const { asset, tab } = useParams();
    const [tokenDetail, setTokenDetail] = useState([]);
    const [isLoaded, setIsloaded] = useState(true);
    const [selectTab, setSelectTab] = useState(tab);
    const [assetTransfers, setAssetTransfers] = useState([]);
    const [assetAccount, setAssetAccount] = useState([]);
    const [page, setPage] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [account, setAccount] = React.useState([]);
    const navigate = useNavigate();

    const handleChangePage = async (props) => {
        const { type, increment } = props;
        console.log('handleChangePage', props)


        if (type !== 'next' && page === 1) {

        }
        else {
            setIsloaded(true)
            setPage(prevState => type === 'next' ? prevState + increment : prevState - increment);
            let state = type === 'next' ? page + 1 : page - 1;
            if (selectTab === '') {
                await getTransaction(state)
            } if (selectTab === 'Reservedaccount') {
                await getAssetAccount(state)
            } if (selectTab === 'Accounts') {
                await getAccounts(state)
            }
        }

    };

    const handleChangeRowsPerPage = async (event) => {
        let rpp = parseInt(event.target.value, 10);
        setRowsPerPage(rpp);
        setIsloaded(true)
        let state = page;
        if (selectTab === '') {
            await getTransaction(state, rpp)
        } if (selectTab === 'Reservedaccount') {
            await getAssetAccount(state, rpp)
        } if (selectTab === 'Accounts') {
            await getAccounts(state, rpp)
        }
    };

    const getTransaction = useCallback((inc = 1, rpp) => {
        const body = {
            secretKey: process.env.REACT_APP_SECRET,
            rpp: rpp ? rpp : rowsPerPage,
            page_no: inc,
            asset: asset,
            account: ""
        }
        return new Promise((resolve, reject) => {
            axios.post(process.env.REACT_APP_BASE_URL + `/api/transaction/list/v01`, body)
                .then((response) => {
                    let res = response.data;
                    if (Object.keys(res.result).length < 1) {
                        navigate(`/not-found/${asset}`);
                    } else {
                        setAssetTransfers(res.result.transactionList);
                    }
                    resolve(res);
                }).catch((err) => {
                    reject(err);
                    console.log(err);
                }).finally(() => {
                    setIsloaded(false);
                });
        });
    }, [rowsPerPage, page]);

    const getAssetAccount = useCallback((inc = 1, rpp) => {
        return new Promise((resolve, reject) => {
            const body = {
                secretKey: process.env.REACT_APP_SECRET,
                rpp: rpp ? rpp : rowsPerPage,
                page_no: inc,
                asset: asset
            }
            axios.post(process.env.REACT_APP_BASE_URL + `/api/asset/reservedaccount/list/v01`, body)
                .then((response) => {
                    let res = response.data;
                    console.log('res', res)
                    if (Object.keys(res.result).length < 1) {
                        // navigate(`/not-found/${asset}`);
                        setAssetAccount([]);
                    } else {
                        setAssetAccount(res.result.reservedAccountList);
                    }
                    resolve(res);

                }).catch((err) => {
                    reject(err);
                    console.log(err);
                }).finally(() => {
                    setIsloaded(false);
                });
        });

    }, [rowsPerPage, page]);
    const getAccounts = useCallback((inc = 1, rpp) => {
        return new Promise((resolve, reject) => {
            const body = {
                secretKey: process.env.REACT_APP_SECRET,
                rpp: rpp ? rpp : rowsPerPage,
                page_no: inc,
                asset: asset
            }
            axios.post(process.env.REACT_APP_BASE_URL + `/api/asset/account/list/v01`, body)
                .then((response) => {
                    let res = response.data;
                    console.log('account/list', res)
                    if (Object.keys(res.result).length < 1) {
                        navigate(`/not-found/${asset}`);
                    } else {
                        setAccount(res.result.accountlist);
                    }
                    resolve(res);

                }).catch((err) => {
                    reject(err);
                    console.log(err);
                }).finally(() => {
                    setIsloaded(false);
                });
        });

    }, [rowsPerPage, page]);
    const getTokenDetail = () => {
        return new Promise((resolve, reject) => {
            axios.post(process.env.REACT_APP_BASE_URL + `/api/asset/detail/v01`, {
                secretKey: process.env.REACT_APP_SECRET,
                asset: asset
            })
                .then((response) => {
                    let res = response.data;
                    setTokenDetail(res.result.asset);
                    resolve(res);
                }).catch((err) => {
                    reject(err);
                    console.log(err);
                }).finally(() => {
                    setIsloaded(false);
                });
        });
    }
    const formatRupiah = (angka = 0, prefix) => {
        var number_string = angka.toString().replace(/[^,\d]/g, ''),
            split = number_string.split(','),
            sisa = split[0].length % 3,
            rupiah = split[0].substr(0, sisa),
            ribuan = split[0].substr(sisa).match(/\d{3}/gi);

        if (ribuan) {
            var separator = sisa ? ',' : '';
            rupiah += separator + ribuan.join(',');
        }
        rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah;
        return prefix === undefined ? rupiah : prefix + ' ' + rupiah;
    };
    const clearState = () => {
        setPage(1);
        setRowsPerPage(5)
    }
    const handleSelectTab = (tab) => {
        clearState();
        if (tab === "") {
            navigate(`/token-detail/${asset}`);
        } else if (tab === 'Reservedaccount') {
            navigate(`/token-detail/${asset}/${tab}`);
        } else if (tab === 'Accounts') {
            navigate(`/token-detail/${asset}/${tab}`);
        }
    };

    const initialization = async () => {
        setSelectTab(tab === undefined ? "" : tab);
        if (tab === undefined) {
            setIsloaded(true);
            await getTokenDetail();
            await getTransaction();
        } else if (tab === 'Reservedaccount') {
            setIsloaded(true);
            await getAssetAccount(page, rowsPerPage ).then((res) => {
            });
        } else if (tab === 'Accounts') {
            setIsloaded(true);
            await getAccounts(page, rowsPerPage).then((res) => {
            });
        }
    }

    useEffect(() => {
        initialization().catch((err) => console.log(err));

    }, [tab]);

    return (
        <Box>
            <Grid container bgcolor={'white'} spacing={0} sx={{ p: 5, pt: 10 }}>
                <Grid item md={12} xs={12} alignItems={"center"} justifyContent={"center"}>
                    <Stack direction={"row"} alignItems={"center"} sx={{ minWidth: 200 }}>
                        <img src={tokenDetail?.asset_imagepath} style={{ height: 35, width: 35 }} />
                        <Typography sx={{ ml: 1 }} style={{ fontWeight: "700", fontSize: 16 }}>{tokenDetail.name} Token</Typography>
                    </Stack>
                </Grid>
                <Grid container spacing={2} sx={{ pt: 2 }}>
                    <Grid item md={6} xs={12}>
                        <Box sx={{ border: 0.5, borderColor: 'gray', p: 1, borderRadius: 2 }}>
                            <Grid container>
                                <Grid item md={12}>Overview</Grid>
                            </Grid>
                            <Divider sx={{ mt: 1, mb: 1 }} />
                            <Grid container spacing={2}>
                                <Grid item md={12} xs={12}>
                                    <Typography style={{ color: grey[500] }}>Total Supply</Typography>
                                    <Typography >{formatTokenDecimals(tokenDetail.quantityQNT, tokenDetail.decimals)} {tokenDetail.name}</Typography>
                                </Grid>
                                <Grid item md={12} xs={12}>
                                    <Typography style={{ color: grey[500] }}>Description</Typography>
                                    <Typography>{tokenDetail.description}</Typography>
                                </Grid>
                                <Grid item md={12} xs={12}>
                                    <Typography style={{ color: grey[500] }}>URL</Typography>
                                    <Typography style={{ color: grey[500] }}>{tokenDetail.url ? tokenDetail.url : "-"}</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item md={6} xs={12} sx={{ pt: 2 }}>
                        <Box sx={{ border: 0.5, borderColor: 'gray', p: 1, borderRadius: 2 }}>
                            <Grid container>
                                <Grid item md={12}>Other Info</Grid>
                            </Grid>
                            <Divider sx={{ mt: 1, mb: 1 }} />
                            <Grid container spacing={2}>
                                <Grid item md={12} xs={12}>
                                    <Typography style={{ color: grey[500] }}>Token ID</Typography>
                                    <Stack direction={"row"}>
                                        <Typography >{tokenDetail.asset}</Typography>
                                        <Button variant="text" style={{ minWidth: 10, height: 20 }} onClick={() => { navigator.clipboard.writeText(tokenDetail.asset) }}>
                                            <FileCopyIcon style={{ color: "#DBBA52", fontSize: 16 }} />
                                        </Button>
                                    </Stack>

                                </Grid>
                                <Grid item md={12} xs={12} >
                                    <Typography style={{ color: grey[500] }}>Creator</Typography>

                                    <Stack direction={"row"}>
                                        <a href="#" style={{ textDecoration: "none" }}><Typography style={{ color: blue[500] }} onClick={() => navigate("/account-detail/" + tokenDetail.account)}>{tokenDetail.account}</Typography></a>
                                        <Button variant="text" style={{ minWidth: 10, height: 20 }} onClick={() => { navigator.clipboard.writeText(tokenDetail.account) }}>
                                            <FileCopyIcon style={{ color: "#DBBA52", fontSize: 16 }} />
                                        </Button>
                                    </Stack>

                                </Grid>
                                <Grid item md={12} xs={12}>
                                    <Typography style={{ color: grey[500] }}>&nbsp;</Typography>
                                    <Typography style={{ color: grey[500] }}>&nbsp;</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>

                </Grid>
                <Grid container sx={{ pt: 2 }}>
                    <Grid item md={12} sx={12} xs={12}>
                        <Box sx={{ border: 0.5, borderColor: 'gray', p: 1, borderRadius: 2, width: '100%' }}>
                            <Grid container>
                                <Stack direction={"row"} flex={1} justifyContent={'space-between'}>
                                    <Stack direction={"row"} spacing={{ md: 3, xs: 2 }}>
                                        <Box sx={{ borderBottom: selectTab === "" ? 2 : 0, color: selectTab === "" ? blue[600] : "" }}>
                                            <Typography onClick={() => handleSelectTab("")} style={{ cursor: 'pointer', color: selectTab === "" ? blue[600] : 'black', fontWeight: selectTab === 1 ? "800" : '500' }}>{`Transactions`}</Typography>
                                        </Box>
                                        <Box sx={{ borderBottom: selectTab === "Reservedaccount" ? 2 : 0, color: selectTab === "Reservedaccount" ? blue[600] : "" }}>
                                            <Typography onClick={() => handleSelectTab("Reservedaccount")} style={{ cursor: 'pointer', color: selectTab === "Reservedaccount" ? blue[600] : 'black', fontWeight: selectTab === 2 ? "800" : '500' }}>Reserved Account</Typography>
                                        </Box>
                                        <Box sx={{ borderBottom: selectTab === "Accounts" ? 2 : 0, color: selectTab === "Accounts" ? blue[600] : "" }}>
                                            <Typography onClick={() => handleSelectTab("Accounts")} style={{ cursor: 'pointer', color: selectTab === "Accounts" ? blue[600] : 'black', fontWeight: selectTab === 3 ? "800" : '500' }}>{`Account(s)`}</Typography>
                                        </Box>
                                    </Stack>
                                    <Stack direction={"row"} spacing={{ md: 3, xs: 2 }}>
                                        <Box sx={{}}>
                                            <Typography>{`Page`} {page}</Typography>
                                        </Box>
                                    </Stack>
                                </Stack>
                            </Grid>
                            <Divider sx={{ mt: 1, mb: 1 }} />
                            <React.Fragment>
                                {selectTab === '' ? <TokenDetailTabTransaction assetTransfers={assetTransfers} dataLength={assetTransfers.length} isLoaded={isLoaded} page={page} rowsPerPage={rowsPerPage} handleChangePage={handleChangePage} handleChangeRowsPerPage={handleChangeRowsPerPage} /> : null}
                                {selectTab === 'Reservedaccount' ? <TokenDetailTabAccount asset={asset} dataLength={assetAccount.length} assetAccount={assetAccount} isLoaded={isLoaded} page={page} rowsPerPage={rowsPerPage} handleChangePage={handleChangePage} handleChangeRowsPerPage={handleChangeRowsPerPage} /> : null}
                                {selectTab === 'Accounts' ? <TokenDetailTabAccount1 asset={asset} dataLength={account.length} assetAccount={account} isLoaded={isLoaded} page={page} rowsPerPage={rowsPerPage} handleChangePage={handleChangePage} handleChangeRowsPerPage={handleChangeRowsPerPage} /> : null}

                            </React.Fragment>
                        </Box>
                    </Grid>
                </Grid>



            </Grid>
        </Box>
    );
}


export default TokenDetail;