import React, { useState, useEffect } from "react";
import { Box, Button, Card, CircularProgress, Divider, Grid, Stack, TextField, Typography } from "@mui/material";
import { CardMedia } from '@mui/material';
import { blue, grey } from "@mui/material/colors";
import { styled } from '@mui/system';
import axios from 'axios';
import moment from 'moment';
import ForwardIcon from '@mui/icons-material/Forward';
import TablePagination, {
  tablePaginationClasses as classes,
} from '@mui/base/TablePagination';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { useNavigate } from "react-router-dom";
import { CustomPagination } from "../../../component/pagination/customPagination";


const CustomTablePagination = styled(TablePagination)(
  ({ theme }) => `
    & .${classes.spacer} {
      display: none;
    }
  
    & .${classes.toolbar}  {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  
      @media (min-width: 768px) {
        flex-direction: row;
        align-items: center;
      }
    }
  
    & .${classes.selectLabel} {
      margin: 0;
    }
  
    & .${classes.select}{
      padding: 2px 6px;
      border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
      border-radius: 50px;
      background-color: transparent;
      color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  
      &:hover {
        background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
      }
  
      &:focus {
        outline: 1px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
      }
    }
  
    & .${classes.displayedRows} {
      margin: 0;
  
      @media (min-width: 768px) {
        margin-left: auto;
      }
    }
  
    & .${classes.actions} {
      padding: 2px;
      border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
      border-radius: 50px;
      text-align: center;
    }
  
    & .${classes.actions} > button {
      margin: 0 8px;
      border: transparent;
      border-radius: 4px;
      background-color: transparent;
      color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  
      &:hover {
        background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
      }
  
      &:focus {
        outline: 1px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
      }
    }
    `,
);

const TokenDetailTabAccount = ({ assetAccount, isLoaded, page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, dataLength }) => {
  
  const navigate = useNavigate();

  return (
    <>
      <Box style={{ maxWidth: '100%', overflowX: 'auto' }}>
        <Grid container>
          <Grid item md={12} xs={30} sx={{ mt: 1, mb: 1 }}>
            <Stack direction={{ md: "row", xs: "row" }} spacing={{ xs: 1, md: 2 }}>
              {/* <Stack direction={"row"} sx={{ minWidth: 200 }}>
                <Typography style={{ fontWeight: "800", fontSize: 14 }}>Asset</Typography>
              </Stack> */}
              <Stack direction={"row"} justifyContent={"flex-start"} sx={{ minWidth: 250 }}>
                <Typography style={{ fontWeight: "800", fontSize: 14 }}>Tag</Typography>
              </Stack>
              <Stack direction={"row"} justifyContent={"flex-start"} sx={{ minWidth: 200 }}>
                <Typography style={{ fontWeight: "800", fontSize: 14 }}>Account</Typography>
              </Stack>
              <Stack direction={"row"} justifyContent={"flex-end"} sx={{ minWidth: 150 }}>
                <Typography style={{ fontWeight: "800", fontSize: 14 }}>Reserved Amount</Typography>
              </Stack>
            </Stack>
            <Divider sx={{ mt: 1, mb: 1 }} />
          </Grid>
        </Grid>
        {!isLoaded && assetAccount ?
          assetAccount.map((x, i) =>
            <>
              <Grid container key={i}>
                <Grid item md={12} xs={30} sx={{ mt: 1, mb: 1 }}>
                  <Stack direction={"row"} spacing={{ xs: 1, md: 2 }}>
                    {/* <Stack direction={"row"} sx={{ minWidth: 200 }}>
                      <a href="#" style={{ textDecoration: 'none' }}><Typography style={{ fontWeight: "500", fontSize: 12, color: blue[500] }} onClick={() => navigate("/transaction-detail/" + x.asset)}>{x.asset}</Typography></a>
                      <Button variant="text" style={{ minWidth: 10, height: 20 }} onClick={() => { navigator.clipboard.writeText(x.asset) }}>
                        <FileCopyIcon style={{ color: "#DBBA52", fontSize: 16 }} />
                      </Button>
                    </Stack> */}

                    <Stack direction={"row"} justifyContent={"flex-start"} gap={1} alignItems={"center"} sx={{ minWidth: 250 }}>
                      <img src={x.imagepath} style={{ width: 25, height: 25, borderRadius: 50 }} />
                      <Typography style={{ fontWeight: "800", fontSize: 14 }}>
                        {x.tag}
                      </Typography>
                    </Stack>
                    {/* <Stack direction={"row"} justifyContent={"flex-start"} sx={{ minWidth: 150 }}>
                      <Typography style={{ fontWeight: "500", fontSize: 12 }}>{x.account}</Typography>
                    </Stack> */}
                    <Stack direction={"row"} justifyContent={"flex-start"} sx={{ minWidth: 200 }}>
                      <a href="#" style={{ textDecoration: "none" }}><Typography style={{ color: blue[500], fontSize: 14 }} onClick={() => navigate("/account-detail/" + x.account)}>{x.account}</Typography></a>

                      <Button variant="text" style={{ minWidth: 10, height: 20 }} onClick={() => { navigator.clipboard.writeText(x.account) }}>
                        <FileCopyIcon style={{ color: "#DBBA52", fontSize: 16 }} />
                      </Button>
                    </Stack>

                    <Stack direction={"row"} justifyContent={"flex-end"} sx={{ minWidth: 150 }}>
                      <Typography style={{ fontWeight: "500", fontSize: 12 }}>{x.reservedQNT}</Typography>
                    </Stack>

                  </Stack>
                  <Divider sx={{ mt: 1, mb: 1 }} />
                </Grid>
              </Grid>

            </>
          )
          : <Box display="flex" justifyContent="center"
            alignItems="center"
            minHeight="50vh">
            <CircularProgress color="inherit" /> &nbsp;
            <Typography style={{ fontSize: 12, color: grey[600] }}>Getting data...</Typography>
          </Box>}
      </Box>
      <Stack>
        <table aria-label="custom pagination table">
          <tfoot>
            <tr>
              <CustomTablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                colSpan={3}
                count={assetAccount ? assetAccount.length : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                slotProps={{
                  select: {
                    'aria-label': 'Show',
                  },
                  actions: {
                    showFirstButton: true,
                    showLastButton: true,
                  },
                }}
                sx={{
                  ".MuiTablePagination-actions .MuiTablePagination-actions": {
                    opacity: 0
                  },
                  ".MuiTablePagination-actions": {
                    opacity: 0
                  },
                  ".MuiTablePagination-displayedRows": {
                    opacity: 0
                  },
                  ".MuiTablePagination-selectLabel": {
                    opacity: 1
                  }
                }}
                onPageChange={rowsPerPage >= dataLength ? handleChangePage : null}
                onRowsPerPageChange={rowsPerPage <= dataLength ? handleChangeRowsPerPage : null}
              />
              <CustomPagination reachLimit={dataLength < rowsPerPage | dataLength === 0 ? true : false} handleChangePage={handleChangePage} />
            </tr>
          </tfoot>
        </table>
      </Stack>
    </>
  );
}


export default TokenDetailTabAccount;