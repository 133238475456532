import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Button, Stack } from "@mui/material";

export const CustomPagination = ( props) => {
    const {reachLimit, handleChangePage} = props
    return (
        <>
            <Stack direction={'row'} sx={{ height: 20 }} justifyContent={'flex-end'}>
                
                <Button onClick={() => handleChangePage({ type: 'back', increment: 1 })} sx={{ backgroundColor: 'rgb(31, 42, 55)', borderTopRightRadius: 0, borderBottomRightRadius: 0, ":hover": { backgroundColor: '#DBBA52' } }}>
                    <NavigateBeforeIcon style={{ color: '#FFFF' }} />
                </Button>
                {
                   !reachLimit?
                   <Button onClick={() => handleChangePage({ type: 'next', increment: 1 })} sx={{ backgroundColor: 'rgb(31, 42, 55)', borderTopLeftRadius: 0, borderBottomLeftRadius: 0, ":hover": { backgroundColor: '#DBBA52' } }}>
                   <NavigateNextIcon style={{ color: '#FFFF' }} />
               </Button>: null
                }
             
            </Stack>
        </>
    )
}