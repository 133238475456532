export const formatRupiah = (angka = 0, prefix) => {
    var number_string = angka.toString().replace(/[^,\d]/g, ''),
      split = number_string.split(','),
      sisa = split[0].length % 3,
      rupiah = split[0].substr(0, sisa),
      ribuan = split[0].substr(sisa).match(/\d{3}/gi);
  
    if (ribuan) {
      var separator = sisa ? '.' : '';
      rupiah += separator + ribuan.join('.');
    }
    rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah;
    return prefix === undefined ? rupiah : prefix + ' ' + rupiah;
  };

export const formatToken = (angka = 0, prefix) =>{
  var number_string = angka.toString().replace(/[^,\d]/g, ''),
  split = number_string.split(','),
  sisa = split[0].length % 3,
  rupiah = split[0].substr(0, sisa),
  ribuan = split[0].substr(sisa).match(/\d{3}/gi);

  if (ribuan) {
    var separator = sisa ? ',' : '';
    rupiah += separator + ribuan.join(',');
  }
  rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah;
  return prefix === undefined ? rupiah : prefix + ' ' + rupiah;
}

export const formatTokenDecimals = (value = "",decimal) =>{
  var getLastLength = value.length - decimal;
  var original = value.substring(0,getLastLength);
  var substring = value.substring(getLastLength);

  var first = formatToken(original.length < 1 ? 0 : original);
  var second = substring === "0" ? '' : '.'+substring;

  return first+''+second;
}

export const converterDecimal = (decimal) => {

  var number = '';

  for(var i =0; i < decimal; i++){
      number += '0';
  }

  return ','+number;
}