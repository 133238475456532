import React from "react";
import Home from '../pages/home';
import Page from '../@dwa/shared/page';
const homeRoutes = [
    {
        path: "/home",
        element: <Page component={Home} layout={"logged"}/>,
    }
];

export default homeRoutes;